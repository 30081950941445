<template >
    <div class="loder_box">
        <img src="/assets/img/lodder.gif" alt="">
    </div>
</template>
<script>
export default {
    name:'EmptylodderBox'
}
</script>
<style >
    .loder_box{
        min-height: 300px;
        background: #ddd;
        text-align: center;
    }
    .loder_box img{
        width:300px;        
       margin-top: 50px;
    }
</style>