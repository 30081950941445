<template lang="">
     <div class="ps-section__content">
                     <div class="ps-carousel--nav owl-slider">
              
                        <carousel :itemsToShow="sliderCont" v-if="render_slider_card" >
                            <slide v-for="(item,index) in items" :key="index">
                                <div class="ps-product">
                                    <div class="ps-product__thumbnail">
                                        <a href="#"><img :src="item.imageUrl" alt="" /></a>

                                    </div>
                                    <div class="ps-product__container">
                                        <div class="ps-product__content">
                                            <a class="ps-product__title" href="#">{{item.elementName}}</a>
                                        </div>

                                    </div>
                                </div>                      
                            </slide>

                            <template #addons>
                            <navigation />
                        
                            </template>
                    </carousel>
                     </div>
                </div> 
</template>
<script>
//import Config from  '../config';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
export default {
    name:'PriceStorebanner',
    components: {
       Carousel,
        Slide,       
        Navigation,
  },
  watch:{
        sliderCont(){
            this.render_slider_card=false;
            let $this=this;
             this.$nextTick(() => {
                // Add the component back in
                $this.render_slider_card = true;
                });
        }
    },
    data(){
        return {
            'items':[
                {
                    "teId":'1',
                    "imageUrl":'/assets/img/offer_1.png',
                    "elementName":'Under AED 99'
                },
                {
                    "teId":'2',
                    "imageUrl":'/assets/img/offer_2.png',
                    "elementName":'AED 100 -AED 199 Store'
                },
                 {
                    "teId":'3',
                    "imageUrl":'/assets/img/offer_3.png',
                    "elementName":'AED 200 -AED 299 Store'
                },
                {
                    "teId":'4',
                    "imageUrl":'/assets/img/offer_4.png',
                    "elementName":'AED 300 -AED 499 Store'
                },
                 {
                    "teId":'5',
                    "imageUrl":'/assets/img/offer_5.png',
                    "elementName":'AED 500 -AED 799 Store'
                },
                {
                    "teId":'6',
                    "imageUrl":'/assets/img/offer_6.png',
                    "elementName":'AED 799 and Above'
                },
                {
                    "teId":'7',
                    "imageUrl":'/assets/img/offer_1.png',
                    "elementName":'Under AED 99'
                }

            ],
            'window': {
                    width: 0,
                    height: 0
                },
            'sliderCont':'5',
            'render_slider_card':true

            
        }
    },
    methods:{
           handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.calculateSlideCount(window.innerWidth);
        },
        calculateSlideCount(width){
            // console.log(width);
                if(width <=360){
                    this.sliderCont=2;
                }else if(width>360 && width <=450){
                     this.sliderCont=2;
                }else if(width>450 && width <=570){
                    this.sliderCont=3;
                }else if(width>570 && width <=767){
                    this.sliderCont=4;
                }else if(width>767 && width <=992){
                    this.sliderCont=4;
                }else if(width>992 && width <=1200){
                    this.sliderCont=6;
                }else if(width>1200 && width <=1920){
                    this.sliderCont=6;
                }else{
                    this.sliderCont=6; 
                }
        } 
    },
       created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>
<style scoped>
    .ps-product{
        width: 100%;
        margin-bottom: 4px;
        margin-right: 10px;
        border: 1px solid #ddd;
    }
</style>