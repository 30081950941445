<template >
        <div class="modal fade" id="forget-password" tabindex="-1" role="dialog" aria-labelledby="forget-password" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
             <!-- Modal content-->
      <div class="modal-content" v-if="translationloaded==true">
        <span class="modal-close" data-dismiss="modal"><i id="icon-cross2" class="icon-cross2" @click="closeModdal()"></i></span>
        <div class="modal-body">
        
            
              <div class="signWrapperMainDiv forgetWrappermain">
              <div class="log_area">    
              <div class="login-box">
                  <template v-if="password_resetform.reset_request_status==true && opt_verify_form.verification_request_status==false">
                       <h3>{{translate('enterOtp')}}</h3>
                       <p> {{translate('OtpHasBeenSendedToEmailSmsWhatsApp')}}</p>
                        <input type="text" id="login" :class="{error_border:opt_verify_form.otp_error}" v-model="opt_verify_form.otp" :placeholder="translate('EnterOtpHere')">
                        <div class="srch_btn"><input type="submit" :value="translate('verifyOtp')" @click="verifyOtp()"></div>
                        <div class="w-100 text-center mt-4">
                            <a href="javascript:void(0)" @click="resentOTP()"> {{translate('resentOTP')}}</a>
                        </div>

                  </template>
                  <template v-else-if="password_resetform.reset_request_status==false">
                            <h3>{{translate('forgotPassword')}}</h3>
                            <p>{{translate('emailId')}}</p>
                            <input type="text" id="login" :class="{error_border:password_resetform.email_error}" v-model="password_resetform.email" :placeholder="translate('enterEmailId')">
                            <h5>--{{translate('or')}}--</h5>
                            <p> {{translate('mobileNumber')}} </p>
                            <div class="row">
                                <div class="col-md-3">
                                    <input type="text" id="country_code" disabled :value="country_code">
                                </div>
                                <div class="col-md-9">
                                     <input type="text" id="login" :class="{error_border:password_resetform.mobile_error}" v-model="password_resetform.mobile" :placeholder="translate('enterMobileNumber')">
                                </div>

                            </div>
                           
                            <div class="srch_btn"><input type="submit" :value="translate('getOTP')" @click="getOtp()"></div>

                            <template v-if="password_resetform.password_reset_error_status==true">
                                <div class="alert alert-danger mt-4" role="alert">
                                {{password_resetform.password_reset_error_msg}}
                                </div>
                            </template>
                  </template>

                  <template v-if="opt_verify_form.verification_request_status==true">

                       <h3>{{translate('resetPassword')}}</h3>
                            <p>{{translate('password')}}</p>
                            <input type="password" id="login" :class="{error_border:password_update_form.password_error}" v-model="password_update_form.password" :placeholder="translate('enterNewPassword')">
                             <p class="text-danger" v-if="password_update_form.password_error">{{password_update_form.password_error_msg}}</p>
                            <p>{{translate('ConfirmPassword')}}</p>
                            <input type="password" id="login" :class="{error_border:password_update_form.confirm_password_error}" v-model="password_update_form.confirm_password" :placeholder="translate('retypePassword')">
                              <p class="text-danger" v-if="password_update_form.confirm_password_error">{{password_update_form.confirm_password_error_msg}}</p>
                            <div class="srch_btn"><input type="submit" :value="translate('updatePassword')" @click="updatepassword()"></div>

                  </template>


                 
              </div>
              </div>
                <div class="signWrapperMain forgetPassDivMain"><div class="login-bnr"><img src="/assets/img/sign-up-image.jpg"></div></div>
              </div>
           
        </div>
       
      </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import Config from  '../config';
import axios from "axios";
import i18next from 'i18next';
export default {
    name:'PasswordReset',
    watch:{

         fetchCurrentLag(lang){
            this.language =lang;
             this.enableTranslation();
         }  

    },
    data(){
        return {
            'country_code':'', 
            'password_resetform':{
                'email':'',
                'mobile':'',
                'email_error':'',
                'mobile_error':'',
                'password_reset_error_status':false,
                'password_reset_error_msg':'',
                'reset_request_status':'',
                'reset_request_response':''
            },
            'opt_verify_form':{
                'otp':'',
                'otp_error':'',
                'verification_request_status':'',
                'verification_request_response':''
            },
            'password_update_form':{
                'password':'',
                'confirm_password':'',
                'password_error':'',
                'password_error_msg':'',
                'confirm_password_error':'',
                'confirm_password_error_msg':'',
                'password_update_request_status':'',
                'password_update_request_response':''
            },
            'language':'',
            'translationloaded':false
        }
    },
       mounted(){
        this.language = this.fetchCurrentLag?this.fetchCurrentLag:Config.DEFAULT_LANG;
        this.enableTranslation();
         this.country_code = Config.COUNTRY_CODE;
    },
    methods: {
        closeModdal(){
                $('#forget-password').removeClass('show');
                $('#forget-password').css({'display':'none'});
                $('.ps-site-overlay').css({'visibility':'hidden','opacity':' 0'});
                 $('.modal-backdrop').remove();
        },
        getOtp(){
                let errorStatus=false;                
                if(this.password_resetform.email=='' && this.password_resetform.mobile==''){
                    
                    this.password_resetform.mobile_error=true;
                    this.password_resetform.email_error=true;  
                                      
               } 
               else if(this.password_resetform.email=='' || this.password_resetform.mobile=='')
               {
                   if(this.password_resetform.email =='' &&  this.password_resetform.mobile ==''){
                     
                        this.password_resetform.email_error=true;  

                   }
                   else{
                        this.password_resetform.email_error=false; 
                        
                   }
                   if(this.password_resetform.email =='' && this.password_resetform.mobile ==''){
                     
                         this.password_resetform.mobile_error=true;
                   }
                    else{
                          this.password_resetform.mobile_error=false;
                        
                    }
               }                                
                
                if(this.password_resetform.mobile_error ==false || this.password_resetform.email_error==false){                       
                         errorStatus=false;
                }else{                  
                      errorStatus=true;
                }

                if(errorStatus == false){
                    let $this =this;

                    axios.get(`${Config.BASE_URL}Customer/ForgotPassword?email=${$this.password_resetform.email}&mobileNumber=${$this.password_resetform.mobile}`,Config.ADMIN_API_HEADER).then(function(response){
                       $this.password_resetform.reset_request_status =true;
                       $this.password_resetform.password_reset_error_status=false;
                       $this.password_resetform.reset_request_response = response.data.Data;
                       $this.$toast.success(`OTP request successfully Send`);
                      
                    }).catch(function(error){
                        $this.password_resetform.password_reset_error_msg = error.response.data.Message;
                        $this.password_resetform.reset_request_status =false;
                        $this.password_resetform.password_reset_error_status=true;
                        $this.$toast.error(`${error.response.data.Message}`);
                    });

                }

               
        },
        verifyOtp(){

                let errorStatus=false;
                if(this.opt_verify_form.otp ==''){
                    this.opt_verify_form.otp_error=true;
                    errorStatus=true;
                                     
                }else{
                    this.opt_verify_form.otp_error=false;
                     errorStatus=false;
                }

                if(errorStatus==false){
                    let $this =this;
                    let otpURLKey = $this.password_resetform.reset_request_response;
                    axios.get(`${Config.BASE_URL}Customer/VerifyOTP?OTP=${$this.opt_verify_form.otp}&OtpUrlKey=${otpURLKey}`,Config.ADMIN_API_HEADER).then(function(response){
                        $this.opt_verify_form.verification_request_status =true;                       
                        $this.opt_verify_form.verification_request_response = response.data.Data;
                        $this.$toast.success(`${response.data.Message}`);
                        
                        }).catch(function(error){
                             $this.opt_verify_form.verification_request_status =false; 
                             $this.$toast.error(`${error.response.data.Message}`);
                            
                        });
                }

        },
        updatepassword(){
                let errorStatus =false;
                if(this.password_update_form.password==''){
                    this.password_update_form.password_error=true;
                    errorStatus  =true;
                }else{
                    if(this.password_update_form.password.length <6){
                        this.password_update_form.password_error=true;
                        errorStatus  =true;
                        this.password_update_form.password_error_msg='Please enter at least 6 characters.';
                    }else{
                        this.password_update_form.password_error_msg='';
                        this.password_update_form.password_error=false;
                    }
                    
                }


                if(this.password_update_form.password=='' ){
                    this.password_update_form.confirm_password_error=true;
                    errorStatus  =true;
                }else{
                    if(this.password_update_form.password !=this.password_update_form.confirm_password){
                        this.password_update_form.confirm_password_error=true;
                        errorStatus  =true;
                        this.password_update_form.confirm_password_error_msg='Enter the same password';
                    }else{
                        this.password_update_form.confirm_password_error=false;
                        this.password_update_form.confirm_password_error_msg='';
                    }
                    
                }

                if(errorStatus==false){
                        let $this =this;
                        let otpUrlKey = $this.opt_verify_form.verification_request_response;
                        let password = $this.password_update_form.password;
                        axios.get(`${Config.BASE_URL}Customer/ResetPassword?OtpUrlKey=${otpUrlKey}&password=${password}`,Config.ADMIN_API_HEADER).then(function(response){
                        $this.password_update_form.password_update_request_status =true;                       
                        $this.password_update_form.password_update_request_response = response.data.Data;
                        $this.$toast.success(`${response.data.Message}`);
                        $this.password_update_form.password='';
                        $this.password_update_form.confirm_password_error='';
                        $this.closeModdal();
                        
                        
                        }).catch(function(error){
                             $this.password_update_form.password_update_request_status =false; 
                             $this.$toast.error(`${error.response.data.Message}`);
                            
                        });
                }

        },

        resentOTP(){
            let $this =this;
            let otpURLKEey = $this.password_resetform.reset_request_response;

                  axios.get(`${Config.BASE_URL}Customer/SendAgainOTP?OtpUrlKey=${otpURLKEey}`,Config.ADMIN_API_HEADER).then(function(response){
                      
                       $this.$toast.success(`${response.data.Message}`);
                      
                    }).catch(function(error){
                        
                        $this.$toast.error(`${error.response.data.Message}`);
                    });

        },
        enableTranslation(){
         let $this =this;

       
        fetch('/locales/translation.json').then(response => response.json()).then(data => {
            
                        i18next.init({
                                    lng: this.language,
                                    debug: true,
                                    resources: {
                                        en: {
                                        translation: data.en,
                                        },
                                        ar: {
                                        translation:  data.ar,
                                        }
                                    
                                    }
                                    
                                    }).then(function(t){                                    
                                        $this.translate=t;  
                                            $this.translationLoadCompleted();                                    
                                        
                                    });
                }); 

         },
        translationLoadCompleted(){       
                this.translationloaded=false;
                this.$nextTick().then(() => {            
                 this.translationloaded=true;
                });
                    
       }
    },
}
</script>
<style scoped>
@media screen and (min-width: 720px) {

}
 .error_border{
      border: 1px solid red !important;
    }

    #country_code{
        border: 1px solid #ddd;
        width: 63px;
        height: 45px;
        padding-left: 14px;
        padding-right: 10px;
        margin-bottom: 11px;
        border-radius: 5px;
        color: #000;
        text-align: left;
        font-weight: normal;
    }

 

    
</style>