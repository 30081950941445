<template>
  <div class="maxwidthproductmain" v-if="translationloaded == true">
    <!-- <div class="new_arrival_badge" >New</div> -->
    <!-- <div class="vegDivMain"> <div class="vegDivMainCircle"></div></div> -->
    <template v-if="is_banner == true">
      <div
        class="product_discount_badge"
        v-if="
          offerBadge &&
            Number.isFinite(productSpecialPrice) &&
            productSpecialPrice != 0 &&
            productPrice > productSpecialPrice
        "
      >
        {{ discountPercentage(product) }}% {{ translate("off") }}
      </div>
      <template
        v-if="
          isNewProduct(product.prDate) != 0 && isNewProduct(product.prDate) <= 7
        "
      >
      </template>
    </template>
    <template v-else>
      <div :class="{ whilistIconwrapper: true }">
        <a
          href="javascript:void(0)"
          @click="addToWishList(product)"
          :class="{ 'is-active': product.IsWishlisted || feverate_status }"
          :id="`wishlist_item_${product.productId}`"
        >
          <i class="fa fa-heart-o" aria-hidden="true"></i>
        </a>
      </div>
      <template
        v-if="
          Number.isFinite(productSpecialPrice) &&
            productSpecialPrice != 0 &&
            productPrice > productSpecialPrice
        "
      >
        <div class="percentageDivGreeen">
          <p>{{ discountPercentage(product) }}% {{ translate("off") }}</p>
        </div>
      </template>
    </template>

    <div class="ps-product__thumbnail img_box">
      <router-link :to="`/Product-detail/${product.urlKey}`">
        <img
          :src="`${web_admin_url}${product.imageUrl}`"
          :alt="product.alt ?? product.prName"
          @click="refreshPageEvent(product.urlKey)"
        />
      </router-link>
      <span class="flagTxtMainall" v-if="product.CountryOfOrigin">
        <img
          :src="
            `${web_admin_url}assets/images/uploads/productimages/${product.CountryOfOrigin}.png`
          "
        />
        {{ product.CountryOfOrigin }}
      </span>
      <!-- <a  href="javascript:void(0)" @click="goToDetailpage(product.urlKey)">
          <img :src="`${web_admin_url}${product.imageUrl}`" alt="" />
      </a> -->
    </div>

    <div class="ps-product__container">
      <div class="product_review_sec" v-if="product.IsReviewCount > 0">
        <span class="rating_box"
          >{{ productReviewCnt(product.IsReviewAvgrating) }}.0
          <i class="fa fa-star"></i
        ></span>
        <!-- ({{productReviewCnt(product.IsReviewCount)}}) -->
      </div>

      <div class="ps-product__content">
        <router-link
          class="ps-product__title"
          :to="`/Product-detail/${product.urlKey}`"
        >
          <span @click="refreshPageEvent(product.urlKey)">{{
            productname
          }}</span>
        </router-link>

        <div class="priceinfo">
          <template
            v-if="
              Number.isFinite(productSpecialPrice) &&
                productSpecialPrice != 0 &&
                productPrice > productSpecialPrice
            "
          >
            <p class="current-price mb-0">
              <img :src="`${web_admin_url}${product.UomIcon}`" alt="" />
              {{ currency }}{{ productSpecialPrice }}

              <span class="discount-price">
                <del>{{ currency }} {{ productPrice }}</del>
              </span>
            </p>
          </template>
          <template v-else>
            <p class="current-price mb-0">
              <img :src="`${web_admin_url}${product.UomIcon}`" alt="" />
              {{ currency }}{{ productPrice }}
            </p>
          </template>
        </div>
        <div
          class="cart-sec"
          v-if="product.stockAvailability != 'Out of Stock'"
        >
          <!--if product contain variation start--->

          <template v-if="productVeriation || optionsWeight">
            <div class="variation_box">
              <div class="variation_select_area" v-if="productVeriation">
                <select
                  class="form-control v_select"
                  v-model="selected_variation"
                  @click="UpdateProductUrlKey"
                >
                  <template
                    v-for="(variation, index) in productVeriation[0].AttrValues"
                    :key="index"
                  >
                    <option :value="variation"
                      >{{ variation.AttrValuename }}

                      <template
                        v-if="
                          Number.isFinite(variation.prSpecialPrice) &&
                            variation.prSpecialPrice != 0 &&
                            variation.prPrice > variation.prSpecialPrice
                        "
                      >
                        <span class="forDesk">
                          <del> {{ currency }} {{ variation.prPrice }} </del> -
                          {{ currency }} {{ variation.prSpecialPrice }}
                        </span>
                      </template>
                      <template v-else>
                        <i class="forDesk">
                          {{ currency }} {{ variation.prPrice }}</i
                        >
                      </template>
                    </option>
                  </template>
                </select>
              </div>
              <div class="variation_select_area" v-if="optionsWeight">
                <!-- <select class="form-control v_select"  v-model="selected_variation"  @click="UpdateProductUrlKey"> -->
                <select
                  class="form-control v_select"
                  v-model="selected_weigt"
                  @click="updateProductWeigt"
                >
                  <template
                    v-for="(listWeight, index) in optionsWeight"
                    :key="index"
                  >
                    <option :value="listWeight"
                      >{{ listWeight.optionLabel }}

                      <!-- <template v-if=" Number.isFinite(variation.prSpecialPrice) && variation.prSpecialPrice!=0 && variation.prPrice >variation.prSpecialPrice ">
                                       <span class="forDesk"> <del> {{currency}} {{variation.prPrice}} </del> - {{currency}} {{variation.prSpecialPrice}} </span>
                                    </template>
                                    <template v-else>
                                      <i class="forDesk">  {{currency}} {{variation.prPrice}}</i>
                                    </template> -->
                    </option>
                  </template>
                </select>
              </div>

              <div class="variation_btn_area">
                <div
                  :class="{
                    'input-group inline-group': true,
                    mob_cart: true,
                    'v-incart': isInCart == 'View in cart',
                  }"
                  v-if="isInCart == 'View in cart'"
                >
                  <div class="input-group-prepend">
                    <button
                      class="btn btn-outline-secondary btn-minus"
                      @click="sub(isInCart)"
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </div>
                  <input
                    class="form-control quantity"
                    min="0"
                    readonly
                    :ref="product.urlKey"
                    name="quantity"
                    v-model="count"
                    type="number"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary btn-plus"
                      @click="add(isInCart)"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>

                <div
                  :class="
                    `cartSecBlock w-100 ${
                      isInCart == 'View in cart' ? ' hide_el' : ''
                    }`
                  "
                  v-if="isInCart != 'View in cart'"
                >
                  <div
                    :class="{ 'w-100': isInCart == 'View in cart' }"
                    @click="addtocart(productUrlKey, productWishlistId)"
                  >
                    <template v-if="cart_ajax == 'closed'">
                      <div class="cat-btn-text">
                        <p class="addcart-btn">
                          <span class="addcart-img"
                            ><img :src="`/assets/img/carticon.png`"/></span
                          >{{ translate(isInCart) }}
                        </p>
                      </div>
                    </template>
                    <template
                      v-if="
                        cart_ajax == 'open' &&
                          cart_product_url_key == productUrlKey
                      "
                    >
                      <div class="cat-btn-text">
                        <p class="addcart-btn">
                          <ButtonLoader
                            :loaderColor="loaderColor"
                            :loaderStyle="loaderStyle"
                            :cart_ajax="cart_ajax"
                          >
                          </ButtonLoader>
                        </p>
                      </div>
                    </template>

                    <template
                      v-if="
                        cart_ajax == 'open' &&
                          cart_product_url_key != productUrlKey
                      "
                    >
                      <div class="cat-btn-text">
                        <p class="addcart-btn">
                          <span class="addcart-img"
                            ><img :src="`/assets/img/carticon.png`"/></span
                          >{{ translate(isInCart) }}
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!--if product contain variation end--->
          <template v-else>
            <div :class="`cartSecBlock  w-100`">
              <div
                :class="{
                  'input-group inline-group': true,
                  mob_cart: true,
                  'v-incart': isInCart == 'View in cart',
                }"
                v-if="isInCart == 'View in cart'"
              >
                <div class="input-group-prepend">
                  <button
                    class="btn btn-outline-secondary btn-minus"
                    @click="sub(isInCart)"
                  >
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
                <input
                  class="form-control quantity"
                  min="0"
                  readonly
                  :ref="product.urlKey"
                  name="quantity"
                  v-model="count"
                  type="number"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary btn-plus"
                    @click="add(isInCart)"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>

            <div
              :class="
                `cartSecBlock w-100 ${
                  isInCart == 'View in cart' ? ' hide_el' : ''
                }`
              "
              v-if="isInCart != 'View in cart'"
            >
              <div
                :class="{ 'w-100': isInCart == 'View in cart' }"
                @click="addtocart(productUrlKey, productWishlistId)"
              >
                <template v-if="cart_ajax == 'closed'">
                  <div class="cat-btn-text">
                    <p class="addcart-btn">
                      <span class="addcart-img"
                        ><img :src="`/assets/img/carticon.png`"/></span
                      >{{ translate(isInCart) }}
                    </p>
                  </div>
                </template>
                <template
                  v-if="
                    cart_ajax == 'open' && cart_product_url_key == productUrlKey
                  "
                >
                  <div class="cat-btn-text">
                    <p class="addcart-btn">
                      <ButtonLoader
                        :loaderColor="loaderColor"
                        :loaderStyle="loaderStyle"
                        :cart_ajax="cart_ajax"
                      >
                      </ButtonLoader>
                    </p>
                  </div>
                </template>
                <template
                  v-if="
                    cart_ajax == 'open' && cart_product_url_key != productUrlKey
                  "
                >
                  <div class="cat-btn-text">
                    <p class="addcart-btn">
                      <span class="addcart-img"
                        ><img :src="`/assets/img/carticon.png`"/></span
                      >{{ translate(isInCart) }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
        <template v-else>
          <div class="cart-sec w-100">
            <div class="out_stock">{{translate('out of stock')}}</div>
          </div>
        </template>
      </div>
      <!-- <div v-if="optionstest">
        <CustomSelect
        :options="optionstest"
      :default="'select'"
      class="select"/>
      </div> -->
    </div>
  </div>
</template>
<script>
// import CustomSelect from "../components/CustomSelect.vue";
import ButtonLoader from "../components/ButtonLoader";
import Config from "../config";
import { mapGetters, mapActions } from "vuex";

import helper from "../helper/helper";
import $ from "jquery";
import moment from "moment";

export default {
  name: "ProductCard",
  props: [
    "product",
    "web_admin_url",
    "cart_ajax",
    "cart_product_url_key",
    "currency",
    "fevare_required",
    "feverate_status",
    "is_banner",
    "offerBadge",
    "newBadge",
    "translationloaded",
    "translate",
  ],
  components: {
    ButtonLoader,
    //  CustomSelect
  },

  data() {
    return {
      count: 1,
      loaderColor: "#fff",
      loaderStyle: {
        width: "35px",
        marginTop: "-7px",
      },
      guest_id: "",
      cust_id: null,
      isOpen: false,
      //  productVeriation:this.product.variationJson,
      productVeriation: JSON.parse(this.product.variationJson),
      productUrlKey: this.product.urlKey,
      productname: this.product.prName,
      productSpecialPrice: this.product.specialPrice,
      productPrice: this.product.unitPrice,
      productWishlistId: this.product.wishlistId,
      selected_variation: this.product.variationJson
        ? JSON.parse(this.product.variationJson)[0].AttrValues[0]
        : "",
      defaultValue: null,
      selected_weigt: '',
      // selected_weigt: this.product.customOptionsJson
      //   ? JSON.parse(this.product.customOptionsJson)[0]
      //   : "",
      optionsWeight: JSON.parse(this.product.customOptionsJson),
    };
  },
  created(){
    
  
       if (this.product.cartOptionId != null) {
     
     
      let productOption = this.product.customOptionsJson;
      if (productOption) {
        let tweight = null;
        let item = JSON.parse(productOption);
        for (let i = 0; i < item.length; i++) {
          tweight = [item[i]];
          if (tweight[0].optionId == this.product.cartOptionId) {
            this.selected_weigt = tweight[0];
            this.productPrice = tweight[0].price;
          }
          
        }
      }
    }
    else if(this.product.cartOptionId == null) {
       
       this.selected_weigt = this.product.customOptionsJson
        ? JSON.parse(this.product.customOptionsJson)[0]
        : ""
        
          }
          // this.mounted_();
        //  if(this.getcartList){
        //         this.getcartList.forEach(function(cart) {
        //           console.log(cart.qty);
        //           this.count = cart.qty;
        //         })
        //   }

      
          
  },
 
  mounted() {
  
    this.admin_api_header = Config.ADMIN_API_HEADER;
    this.guest_id = localStorage.getItem("guestId");
    this.cust_id = localStorage.getItem("custId");
    // this.count = this.product.CartItemQty != 0 ? this.product.CartItemQty : 1;
   
    
          
    


    // if (this.product.cartOptionId != null) {
     
     
    //   let productOption = this.product.customOptionsJson;
    //   if (productOption) {
    //     let tweight = null;
    //     let item = JSON.parse(productOption);
    //     for (let i = 0; i < item.length; i++) {
    //       tweight = [item[i]];
    //       if (tweight[0].optionId == this.product.cartOptionId) {
    //         this.selected_weigt = tweight[0];
    //         this.productPrice = tweight[0].price;
    //       }
          
    //     }
    //   }
    // }
    // else if(this.product.cartOptionId == null) {
       
    //    this.selected_weigt = this.product.customOptionsJson
    //     ? JSON.parse(this.product.customOptionsJson)[0]
    //     : ""
        
    //       }
    //  if(this.product.customOptionsJson != null){
    //    this.defaultValue = this.product.customOptionsJson;

    //    let item =JSON.parse(this.defaultValue);
    //    let tweight = null
    //    for(let i = 0; i < item.length; i++){
    //       tweight = [item[i]];
    //         if(typeof tweight[0].optionId != "undefined"){
    //     this.defaultValue = tweight[0];
    //       console.log(this.defaultValue);
    //     }
    //    }
    //  }
  },


  methods: {
    ...mapActions([
      "storeCustomerWishlist",
      "removeCustomerWishlist",
      "getCustomerWishlist",
    ]),
    // mounted_(){

     
    //      if (this.product.cartOptionId != null) {
     
     
    //   let productOption = this.product.customOptionsJson;
    //   if (productOption) {
    //     let tweight = null;
    //     let item = JSON.parse(productOption);
    //     for (let i = 0; i < item.length; i++) {
    //       tweight = [item[i]];
    //       if (tweight[0].optionId == this.product.cartOptionId) {
    //         this.selected_weigt = tweight[0];
    //         this.productPrice = tweight[0].price;
    //       }
          
    //     }
    //   }
    // }
    // else if(this.product.cartOptionId == null) {
       
    //    this.selected_weigt = this.product.customOptionsJson
    //     ? JSON.parse(this.product.customOptionsJson)[0]
    //     : ""
        
    //       }
    // },

    UpdateProductUrlKey() {
      this.productUrlKey = this.selected_variation.prUrlkey;
      this.productname = this.selected_variation.prName;
      this.productSpecialPrice = this.selected_variation.prSpecialPrice;
      this.productPrice = this.selected_variation.prPrice;
    },
    updateProductWeigt() {
      if (this.selected_weigt) {
        if (typeof this.selected_weigt.optionId !== "undefined") {
          this.productPrice = this.selected_weigt.price;
          this.productSpecialPrice = null;
          this.productUrlKey = this.product.urlKey;
        } else if (!this.product.variationJson) {
          this.productUrlKey = this.product.urlKey;
          this.productname = this.product.prName;
          this.productSpecialPrice = this.product.specialPrice;
          this.productPrice = this.product.unitPrice;
        } else {
          this.UpdateProductUrlKey();
        }
      }
    },
    // openClose() {

    //    let _this = this;
    //   //   const closeListerner = (e) => {
    //   //     if ( _this.catchOutsideClick(e, _this.$refs.menu ) )
    //   //       window.removeEventListener('click', closeListerner) , _this.isOpen = false

    //   //   }

    //   //  window.addEventListener('click', closeListerner)
    //   _this.isOpen = !_this.isOpen;

    // },
    // catchOutsideClick(event, dropdown)  {
    //   // When user clicks menu — do nothing
    //   if( dropdown == event.target )
    //     return false

    //   // When user clicks outside of the menu — close the menu
    //   if( this.isOpen && dropdown != event.target )
    //     return true

    // },
    goToDetailpage(urlkey) {
      this.$router.push({ path: `/product-detail/${urlkey}` });
    },
    refreshPageEvent(product_urlKey) {
      this.$emit("refreshSliderdata", product_urlKey);
    },
    discountPercentage(product) {
      if (product.unitPrice > product.specialPrice) {
        let difference = product.unitPrice - product.specialPrice;

        let percentage = (difference * 100) / product.unitPrice;

        return percentage.toFixed(2);
      } else {
        return 0;
      }
    },
    addtocart(url_key, wishlistId = "") {
      // let productQty = this.$refs[url_key].value;
      let productQty = 1;
      let optionId = null;
      if (this.selected_weigt) {
        if (typeof this.selected_weigt.optionId !== "undefined") {
          optionId = this.selected_weigt.optionId;
        }
      }
      let productInfo = {
        url_key: url_key,
        product_qty: productQty,
        optionId: optionId,
      };

      if (this.isInCart == "Add To Cart") {
        if (this.feverate_status != true) {
          this.$emit("addToCart", productInfo);
        } else {
          let payload = {
            custId: localStorage.getItem("custId"),
            guestId: null,
            WishlistId: wishlistId,
            productQty: productQty,
            vendorUrlkey: Config.VENDOR_URL_KEY,
            url_key: url_key,
          };
          this.$emit("moveToCart", payload);
        }
      } else {
        $("#cart-mobile").css({ left: "unset", right: "0" });
        $(".ps-panel--sidebar").removeClass("active");
        $("#cart-mobile").addClass("active");
        $(".ps-site-overlay").addClass("active");
      }

      if (this.count == 0) {
        this.count = 1;
      }
    },
    add(isInCart) {
      if (this.count < this.product.qoh) {
        this.count++;
      }

      if (isInCart == "View in cart") {
        this.updateCartQuntity("add");
      }
    },
    sub(isInCart) {
      if (this.count > 1) {
        this.count--;
      } else {
        this.count = 0;
      }

      if (isInCart == "View in cart") {
        this.updateCartQuntity("sub");
      }
    },
    updateCartQuntity(type) {
      let cartItemInfo = {
        product_qty: this.count,
        url_key: this.productUrlKey,
        type: type,
        cart_item_id: 0,
      };
      if (this.count > -1) {
        this.$emit("updateCartQty", cartItemInfo);
        // console.log(cartItemInfo);
      }
    },

    addToWishList(productInfo) {
      let isLoggedIn = helper.isLoginedUser();
      let $this = this;
      if (isLoggedIn == true) {
        if (
          $(`#wishlist_item_${productInfo.productId}`).hasClass("is-active")
        ) {
          let payload = {
            custId: localStorage.getItem("custId"),
            guestId: "",
            urlKey: productInfo.urlKey,
          };
          $(`#wishlist_item_${productInfo.productId}`).removeClass("is-active");
          $this.removeCustomerWishlist(payload).then(function(response) {
            $this.$toast.success(`${response.data.Message}`);
          });
        } else {
          $(`#wishlist_item_${productInfo.productId}`).addClass("is-active");

          let payload = {
            custId: $this.cust_id != null ? $this.cust_id : "",
            guestId: $this.cust_id == null ? $this.guest_id : "",
            urlKey: productInfo.urlKey,
          };

          $this.storeCustomerWishlist(payload).then(function(response) {
            $this.$toast.success(`${response.data.Message}`);
          });
        }
      } else {
        document.getElementById("login_popup_link").click();
        // this.$router.push({'path':'/',query:{'login':'failed'}});
      }
    },
    isNewProduct(proDate) {
      let diff = 0;
      if (this.product.prDate != "") {
        diff = moment().diff(proDate, "Days");
      }

      return diff;
    },
    productReviewCnt(value) {
      return Math.ceil(value);
    },
  },
  computed: {
    ...mapGetters(["getcartList", "getUomData"]),
    
    isInCart() {
      let $this = this;
      let addcartBtnText = "Add To Cart";
    
      this.getcartList.forEach(function(cart) {
     
        
        if (
          $this.selected_weigt.optionId == "undefind" ||
          ($this.selected_weigt.optionId == null && cart.optionId == null)
        ) {
          if (cart.urlKey == $this.productUrlKey) {
            addcartBtnText = "View in cart";
           
            $this.count = cart.qty;
           
          }
        } else if (cart.optionId == $this.selected_weigt.optionId) {
          addcartBtnText = "View in cart";
        
          $this.count = cart.qty;
        }
      });
      return addcartBtnText;
    },
    // isInCart() {
    //   let $this = this;
    //   let addcartBtnText = "Add To Cart";
    //   console.log(this.getcartList);
    //   this.getcartList.forEach(function(cart) {
    //     console.log( $this.product.customOptionsJson);
    //     console.log( cart);

    //     if (cart.urlKey == $this.productUrlKey) {
    //       addcartBtnText = "View in cart";
    //       $this.count =cart.qty;
    //     }
    //   });
    //   return addcartBtnText;
    // },

    getuomImage() {
      let imageUrl = "";
      let $this = this;
      if ($this.getUomData != "" && $this.product != "") {
        $this.getUomData.forEach(function(item) {
          if (item.uomName == $this.product.uom) {
            imageUrl = item.UomIcon;
          }
        });
      }
      // console.log(imageUrl);

      return imageUrl;
    },
    //   doubleX: function() {
    //   return this.mounted_();
    // }
   
  },
};
</script>
<style scoped>
.variation_box {
  width: 100%;
}
.variation_select_area {
  float: left;
  width: 50%;
  padding-right: 4px;
}
.variation_btn_area {
  float: left;
  width: 50%;
  padding-left: 4px;
}
.variation_select_area select {
  height: 30px;
  border-radius: 5px;
  font-size: 11px;
  padding: 0 10px;
}

.variation_product i {
  font-style: normal;
  padding-left: 20px;
}
.variation_product {
  position: absolute;
  background: #fff;
  width: 100%;
  text-align: left !important;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 999;
}
.vegDivMain {
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  justify-content: center;
  -webkit-content: center;
  -moz-content: center;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  border: 1px #28a745 solid;
  width: 13px;
  height: 13px;
  border-radius: 3px;
  position: absolute;
  right: 29px;
  top: 21px;
  z-index: 10;
}
.vegDivMainCircle {
  background: #28a745;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.variation_product p {
  font-size: 1.6rem;
  color: #023f88;
}
.variation_product li {
  position: relative;
  margin-bottom: 5px;
}
.variation_product span {
  position: absolute;
  right: 0px;
}
.variation_input {
  left: 0px !important;
}

.cat-btn-text {
  position: relative;
}
.variation_oprions {
  position: absolute;
  right: -30px;
  top: -8px;
  font-size: 34px;
  color: #023f88;
}
.ps-product__content {
  min-height: 123px;
}
.maxwidthproductmain .ps-product__title {
  height: 40px;
}

.cartSecBlock {
  width: 84px !important;

  padding: 0px 3px;
  position: relative;
  margin: 0 auto;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  justify-content: center;
  -webkit-content: center;
  -moz-content: center;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
}
.addcart-img {
  display: inline-block;
  margin-right: 7px;
}
.addcart-img img {
  width: 18px;
  margin-top: -2px;
}
.fevarate {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 4px;
  color: red;
  font-size: 24px;
}
.flagTxtMainall {
  position: relative;
  padding-left: 15px;
  color: #d81c24;
  text-transform: uppercase;
}
.flagTxtMainall img {
  position: absolute;
  left: 0;
  top: 2px;
  width: 12px;
}
.percentageDivGreeen {
  z-index: 1;
}
.whilistIconwrapper {
  z-index: 99;
}
.whilistIconwrapper:hover {
  z-index: 99;
}
.is-active {
  background: #d81c24;
}
.whilistIconwrapper i {
  color: #fff;
}

.maxwidthproductmain .img_box img {
  margin: 0 auto !important;
}
.cart-sec {
  min-width: 100px;
}
.hide_el {
  display: none;
}
.maxwidthproductmain .ps-product__title {
  font-size: 14px;
  font-weight: 400;
  color: #19191a;
  line-height: 21px;
  display: block;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-width: 217px;
  margin: 7px auto 10px;
  height: 42px !important;
}
.new_arrival_badge {
  background: #023f88 !important;
  position: absolute;
  width: 56px;
  text-align: center;
  right: -17px;
  z-index: 100;
  color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: block;
  top: 16px;
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.product_discount_badge {
  z-index: 99;
  top: 8px;
  position: absolute;
  font-size: 11px;
  display: block;
  border: 1px #87c462 solid;
  color: #a2cb82;
  width: 74px;
  border-radius: 4px;
  background: #f2fef2;
  right: 10px;
  padding: 3px;
  font-weight: normal;
}
.out_stock {
  text-align: center;
  color: #e81111;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
}

@media screen and (max-width: 567px) {
  .forDesk {
    display: none;
  }
  .variation_btn_area .mob_cart {
    width: 100%;
  }

  .variation_btn_area .cat-btn-text {
    width: 100%;
    margin-top: 0px !important;
  }

  .mob_cart .btn {
    padding: 0.375rem 4px;
  }

  .maxwidthproductmain {
    padding: 37px 8px 15px;
  }
  .current-price img {
    display: none;
  }
  .maxwidthproductmain .priceinfo p {
    padding-left: 0px;
  }
  .addcart-img img {
    width: 14px;
    margin-top: -4px;
  }

  .cartSecBlock {
    width: auto !important;
  }
  .mob_cart {
    width: 74spx;
    margin: 0 auto;
  }
  .cat-btn-text {
    width: 107px;
    margin: 0 auto;
    margin-top: 5px;
  }
}

.ps-product__container {
  /* position: relative; */
  margin-top: 0px;
}
.product_review_box {
  width: 70px;
  display: inline-block;
  margin-bottom: 5px;
}
.product_review_sec {
  width: 70px;
  display: inline-block;
  left: 5px;
  position: absolute;
  /* top: -22px; */
  top: 8px;
}

.product_review_sec .rating_box {
  background: #71ce63;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 20px;
}

/*  */
</style>
