import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toaster from '@meforma/vue-toaster';
import VueCookies from 'vue3-cookies';
import VueEasyLightbox from 'vue-easy-lightbox'





createApp(App).use(store)
.use(router)
.use(Toaster,{ position: 'bottom-right',duration:'3000'})
.use(VueCookies).use(VueEasyLightbox)
.mount('#app')
