<template >
     <div class="ps-section__content" v-if="translationloaded==true">
                   
                    <carousel :itemsToShow="sliderCont"  v-if="render_slider_card" :mouse-drag="false">
                            <slide v-for="product in products" :key="product.productId">
                                        <div class="produtDeatilsDivFloat" > 
                                            <template v-if="checkOfferExipred(product)">
                                                 <ProductTimer v-if="product.dealTo" :product="product"
                                                :translationloaded="translationloaded"
                                                :translate="translate"/>
                                            </template> 
                                             <template v-else>
                                                <span class="endSaleBgsecTxt dummy"></span>
                                            </template>
                                             
                                            <ProductCard 
                                                           
                                                            :product="product"
                                                            :web_admin_url="web_admin_url" 
                                                            @addToCart="addtocart($event)"
                                                            :cart_ajax="cart_ajax"
                                                            :cart_product_url_key="cart_product_url_key"
                                                            :currency="currency"
                                                            :is_banner="true"
                                                            :offerBadge="true"
                                                            :newBadge="true"
                                                             @refreshSliderdata="refreshPageEvents"
                                                            @updateCartQty="updateCart($event)" 
                                                            :translationloaded="translationloaded"
                                                            :translate="translate"
                                                            >
                                            </ProductCard>
                                            
                                        </div>             
                            </slide>

                            <template #addons>
                            <navigation />
                           
                            </template>
                        </carousel>
                   
                </div>
</template>
<script>
import Config from  '../config';
import helper from '@/helper/helper'
import axios from "axios";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import ProductCard from '../components/ProductCard';
import ProductTimer from '../components/product/ProductTimer';
import {mapActions } from "vuex";
import moment from 'moment'
export default {
    name:'ProductSlider',
    props:[
        'products',
        'offerBadge',
        'newBadge',
        'translationloaded',
        'translate'
    ],
    watch:{
        sliderCont(){
            this.render_slider_card=false;
            let $this=this;
             this.$nextTick(() => {
                // Add the component back in
                $this.render_slider_card = true;
                });
        }
    },
    data(){
            return {
                'web_admin_url':'',
                'currency':'',
                'cart_ajax':'closed',
                'cart_product_url_key':'',
                'vendor_url_key':'',
                'guest_id':'',
                'cust_id':null,
                'pincode':'',
                'window': {
                    width: 0,
                    height: 0
                },
                'sliderCont':'5',
                'button_label':'Add',
                'render_slider_card':true
                

            }
    },
    components: {
       Carousel,
        Slide,       
        Navigation,
        ProductCard,
        ProductTimer
      
        
  },mounted(){
          this.web_admin_url =Config.WEB_ADMIN_URL;
          this.currency = Config.CURRECNCY;    
          this.vendor_url_key =Config.VENDOR_URL_KEY;      
          this.cust_id=localStorage.getItem("custId");
            if(this.cust_id==null){
                this.guest_id =localStorage.getItem("guestId");
            }

            
         
    },
    methods:{
        ...mapActions(['storeCartSummary']),
         discountPercentage(product){
            if(product.unitPrice >product.specialPrice){

                 let difference= product.unitPrice -product.specialPrice;

                let percentage = (difference *100)/product.unitPrice;

                return percentage.toFixed(2);

            }else{
                return 0;
            }

        },
            addtocart(productInfo){
            let urlKey = productInfo.url_key;           
            let productQty = productInfo.product_qty;
            let optionId_ = null;
            if(productInfo.optionId == null){
                optionId_= null;
            }else{
                    optionId_ = productInfo.optionId.toString();
            }
            
            let $this = this;
            $this.cart_ajax ='open';
            $this.cart_product_url_key =productInfo.url_key;
            // console.log(productInfo.optionId.toString());
            let payload ={
                'cusId':($this.cust_id!=null?Number($this.cust_id):''),
                'guestId':($this.cust_id==null?$this.guest_id:''),
                'pincode':$this.pincode,
                'urlKey':urlKey,
                'productQty':productQty,
                'vendorUrlkey': $this.vendor_url_key,
                'optionId' : optionId_, 
            };
           

             $this.$store.dispatch("addToCart",{payload:payload,toast:$this.$toast}).then(function(){
                //  console.log(response);
                    $this.cart_ajax ='closed';
                    $this.cart_product_url_key ='';
                    $this.customerCart = $this.$store.state.customer_cart;
                    $this.$emit('cartUpdated',$this.customerCart);
                    $this.button_label ='View in cart';
                    // $(`.is_in_cart_${productInfo.url_key}`).html('View in cart');
                   
            });

        },
         refreshPageEvents(product_urlKey){
          
            this.$emit('refreshSliderdata',product_urlKey);
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.calculateSlideCount(window.innerWidth);
            
        },
        calculateSlideCount(width){
         //   console.log(width);
                if(width <=360){
                    this.sliderCont=2;
                    //  this.$emit('scren_size_change', this.window.width)
                }else if(width>360 && width <=450){
                     this.sliderCont=2;
                     //this.$emit('scren_size_change', this.window.width)
                }else if(width>450 && width <=570){
                    this.sliderCont=2;
                   
                }else if(width>570 && width <=767){
                    this.sliderCont=2;
                    // this.$emit('scren_size_change', this.window.width)
                }else if(width>767 && width <=992){
                    this.sliderCont=3;
                   // this.$emit('scren_size_change', this.window.width)
                }else if(width>992 && width <=1200){
                    this.sliderCont=5;
                    // this.$emit('scren_size_change', this.window.width)
                }else if(width>1200 && width <=1920){
                    this.sliderCont=6;
                    // this.$emit('scren_size_change', this.window.width)
                }else{
                    this.sliderCont=6; 
                    // this.$emit('scren_size_change', this.window.width)
                }
        },
         truncateString(string,number){
           return  helper.truncateString(string,number);
        },
        updateCart(cartItemInfo){
     
       if(cartItemInfo.product_qty==0){
          this.rmvCartItem(cartItemInfo);
       }
       
       if(cartItemInfo.product_qty!=0){

        let guestId=null;
        let custId =null;
        custId=localStorage.getItem("custId");
        if(custId==null){
            guestId =localStorage.getItem("guestId");
        }

                let  $this =this;  
                let cartUpdateUrl ='';
                if(cartItemInfo.type =='add' ){
                    cartUpdateUrl =`${Config.BASE_URL}Order/CartItemAddQtyByUrlKey`;
                }else if(cartItemInfo.type =='sub'){       
                    cartUpdateUrl =`${Config.BASE_URL}Order/CartItemSubQtyByUrlKey`;
                }
                if(cartUpdateUrl!=''){
                     axios.post(`${cartUpdateUrl}`,{
                       "urlKey":cartItemInfo.url_key,
                        "guestId": guestId,
                        "custId":custId
                     },Config.ADMIN_API_HEADER).then(function(response){
                        if(response.status==200){
                            
                             

                               let payload ={
                                'cusId':custId,
                                'guestId':guestId,
                                'vendorUrlkey': Config.VENDOR_URL_KEY         
                                };
                                $this.$store.dispatch("getCart",payload).then(function(){                                   
                                    $this.$emit('cartUpdated',$this.cart_info);
                                    $this.storeCartSummary();
                                  
                                });
                            
                        }
                    }).catch(function(error){                       
                        $this.$toast.error(`${error.response.data.Message}`);
                         $this.qtyReset =1;
                    });
                }

       }
  
    },
    rmvCartItem(cartItemInfo){
       let $this = this;
       $this.cart_ajax = 'open';
       let guestId=null;
        let custId =null;
        custId=localStorage.getItem("custId");
        if(custId==null){
            guestId =localStorage.getItem("guestId");
        }

    
         axios.post(`${Config.BASE_URL}Order/RemoveCartItemByUrlkey`,{
                       "urlKey":cartItemInfo.url_key,
                        "guestId": guestId,
                        "custId":custId
                     },Config.ADMIN_API_HEADER).then(function(response){
                        if(response.status==200){
                             $this.cart_ajax = 'closed';                             

                               let payload ={
                                'cusId':custId,
                                'guestId':guestId,
                                'vendorUrlkey': Config.VENDOR_URL_KEY         
                                };
                                $this.$store.dispatch("getCart",payload).then(function(){                                   
                                    $this.$emit('cartUpdated',$this.cartItemInfo);
                                   // $this.storeCartSummary();
                                  
                                });
                            
                        }
                    }).catch(function(error){                       
                        $this.$toast.error(`${error.response.data.Message}`);
                         $this.qtyReset =1;
                    });
    
            } ,
        formatedDate(date,withoutTime=''){
            if(date!='' && withoutTime==''){
                //   return moment(date).format('MMMM D, YYYY, h:mma');
                 return moment(date).format('MMMM D');
            }else{
                 return moment(date).format('MMMM D');
            }
          
        },
        checkOfferExipred(product){
           
            let d1 = new Date(product.dealTo)
            let d2 = new Date(new Date().toLocaleString("en-US", {timeZone: "Asia/Muscat"}))
            if(d1>=d2){
                return true;
            }else{
                return false;
            }
        }
      
       
    }, 
    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    

   


    
}
</script>
<style scoped>

.endSaleBgsecTxt.dummy{
  background: transparent!important;
  min-height: 24px!important;
}

.current-price{
    font-weight: 900;
    font-size: 15px;
}

.ps-product__title{
    height: 34px;
}

.discount-price{
    color: #a2a2a2;
    font-size: 16px;
}

.produtDeatilsDivFloat{
    width: 100%;
}

.maxwidthproductmain  .img_box img{ margin: 0 auto!important; width: 100% !important;}

.produtDeatilsDivFloat .ps-product__title{
   font-size: 15px;
    font-weight: bold;
    color: #000;
    line-height: 23px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    max-width: 147px;
    height: 25px!important;
    padding: 0px;
}

/* 
@media only screen and (min-width: 768px)and (max-width: 1200px) {
    .carousel__slide{
        margin-right: 7px;
        width: 32.3333%  !important;
    }
    
}

@media only screen and (max-width: 767px) {
    .carousel__slide{
        margin-right: 10px;
         width: 30.3333%;
    }
    .ps-product__title{
        height:30px !important
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .carousel__slide{
       
         width: 24.5%  !important; 
    }
}
@media (min-width:  1201px) and (max-width: 1920px){
    .carousel__slide{
          margin-right: 7px;
          width: 16.2%  !important; 
    }
} */


    
</style>