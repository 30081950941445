<template lang="">
        <div class="ps-site-features" v-if="translationloaded==true">
            <div class="container">
                 <h3>{{translate('shopFromCategories')}}</h3>
                <div class="ps-section__content shopCategoriesDiv">
                     <div class="top_cat_desktop">
                        <carousel :settings="settings" :breakpoints='breakpoints' v-if="render_slider_card" >
                                <slide v-for="category in top_categories" :key="category.catId">
                                    <div class="ps-product">
                                            <div class="ps-product__thumbnail">
                                                <router-link :to="`/products/${category.catUrlKey}`">
                                                    <img :src="`${web_admin_url}${category.imageUrl}`" :alt="category.catName" />
                                                </router-link>
                                                
                                            </div>
                                            
                                                
                                                    <router-link  class="ps-product__title" :to="`/products/${category.catUrlKey}`">{{category.catName}}</router-link>
                                                
                                               

                                            
                                        </div>                         
                                </slide>

                                <template #addons>
                                <navigation />
                            
                                </template>
                            </carousel>
                        </div>
                    <div class="top_cat_mob">
                        <template v-if="render_slider_card" >
                            <div class="cat_block" >
                                <template v-for="(category,index) in top_categories" :key="category.catId">
                                    <div :class="{cat_block_mob:true,cathide:(mobShowMoreCatStatus?index>5:false)}">
                                        <div class="ps-product">
                                                <div class="ps-product__thumbnail">
                                                    <router-link :to="`/products/${category.catUrlKey}`">
                                                        <img :src="`${web_admin_url}${category.imageUrl}`" :alt="category.catName" />
                                                    </router-link>
                                                    
                                                </div>
                                                <div class="ps-product__container">
                                                    <div class="ps-product__content">
                                                        <router-link  class="ps-product__title" :to="`/products/${category.catUrlKey}`">{{category.catName}}</router-link>
                                                    
                                                    </div>

                                                </div>
                                        </div>    
                                    </div>
                                        

                                </template>
                                <!-- <div class="catShowMore">
                                    <a href="javascript:void(0)" @click="enableAllCategory"> {{mobShowMoreCatStatus? translate('Show more'):translate('View Less')}}</a>
                                </div> -->
                            </div>
                        </template>

                     </div> 
                    
                        
                     
                 
                </div>
            </div>
        </div>
</template>
<script>
import Config from  '../config';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
export default {
    name:'TopCategorySlider',
    props:[
        'top_categories',
         'translationloaded',
         'translate'
    ],
     components: {
       Carousel,
        Slide,
       
        Navigation,
  },
  
   watch:{
        // sliderCont(){
        //     this.render_slider_card=false;
        //     let $this=this;
        //      this.$nextTick(() => {
        //         // Add the component back in
        //         $this.render_slider_card = true;
        //     });
        // }
    },
  data(){
      return {
          'web_admin_url':'',
           'window': {
                    width: 0,
                    height: 0
                },
            'sliderCont':'5',
            'render_slider_card':true,
            'mobShowMoreCatStatus':true,
            settings: {
                itemsToShow: 4,
                snapAlign: 'center',
                },
                    breakpoints: {
                        // 700px and up
                        700: {
                            itemsToShow: 3,
                            snapAlign: 'center',
                        },
                        // 1024 and up
                        1024: {
                            itemsToShow: 4,
                            snapAlign: 'start',
                        },
                        },
                      
                        
           
                
            
      }
  },
  mounted(){
          this.web_admin_url =Config.WEB_ADMIN_URL;
         
    },
    methods:{
       
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.calculateSlideCount(window.innerWidth);
        },
        enableAllCategory(){
            this.mobShowMoreCatStatus =!this.mobShowMoreCatStatus;
        },
        // calculateSlideCount(width){
        //     // console.log(width);
        //         if(width <=360){
        //             this.sliderCont=2;
        //         }else if(width>360 && width <=450){
        //              this.sliderCont=2;
        //         }else if(width>450 && width <=570){
        //             this.sliderCont=2;
        //         }else if(width>570 && width <=767){
        //             this.sliderCont=2;
        //         }else if(width>767 && width <=992){
        //             this.sliderCont=3;
        //         }else if(width>992 && width <=1200){
        //             this.sliderCont=4;
        //         }else if(width>1200 && width <=1920){
        //             this.sliderCont=5;
        //         }else{
        //            // this.sliderCont=5; 
        //         }
        // } 
    },created(){
        // window.addEventListener('resize', this.handleResize);
        // this.handleResize();
    },
    unmounted() {
        // window.removeEventListener('resize', this.handleResize);
    },
}
</script>
<style scoped>
    .catShowMore{
        text-align: center;
        padding: 10px;
        width: 100%;
        clear: both;
        color: #023f88;
        font-weight: bold;
        cursor: pointer;

    }
    .cathide{
        display:none;
    }
    .ps-product{
        border: none !important;
       
    }
 
    .top_cat_mob{
        display: none;
    }
    .cat_block{
         float: left!important;
         width:100%!important;
         background: #fff!important;
         /* padding: 20px; */
    }
    .cat_block_mob{
        float: left!important;
        width:33.33%!important;
        border:1px dashed #e8e8e8a1;
       
    }

    .top_cat_desktop{
        padding-top:20px;
    }

    .ps-product .ps-product__thumbnail img {
         width: 200px;
            margin: 0 auto;
        }

    .shopCategoriesDiv .ps-product__thumbnail{
        max-height:auto!important;
        width:auto!important;
   
    }   
    
    .shopCategoriesDiv .ps-product__title{
        line-height: 28px;
        font-size: 15px;
    }
    @media only screen and (max-width: 600px){
        .ps-site-features h3{font-size: 20px;}
            .top_cat_mob{
                display: block !important;
            }
            .top_cat_desktop{
                 display: none !important;
            }



            .ps-site-features{
                /* background:#d25e62; */
                background: #fff;
                float: left;
                width: 100%;
                margin-top: 0px!important;
                z-index: 99999;
                position: relative;
                margin-bottom: 20px;
               
            }
            /* .ps-site-features h3{
               
            } */

            .shopCategoriesDiv .ps-product__thumbnail {
             width:auto !important;
             max-width: auto !important;
            }
            .ps-product {
               
                min-height: 177px!important;
            }
              
         
         .shopCategoriesDiv .ps-product__title {
             line-height: 16px !important;
font-size: 13px !important;
max-height: 93px;
min-height: 35px;
            }

           
            
        }

          @media only screen and (max-width: 467px){
                 #homepage-3 .ps-site-features {
                padding-top: 20px!important;
                }
                .shopCategoriesDiv .ps-product__thumbnail {
                    min-height: 94px!important;
                }
.cat_block_mob{ width:50%!important}
            .ps-product{
                    border: none !important;
                    min-height: auto;
                }
            
          }

        
</style>