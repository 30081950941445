<template >
     <div class="ps-panel--sidebar" id="navigation-mobile" v-if="translationloaded==true" >
        <div class="ps-panel__header">
             <span class="cat_close">X</span>
            <h3>{{translate('category')}}</h3>
        </div>
        <div class="ps-panel__content">
            <ul class="menu--mobile">
                <template  v-for="category in maincategory" :key="category.catId">
                         <li :class="{'current-menu-item':true, 'menu-item-has-children':true, 'has-mega-menu':(findDepthOfCatTree(category.catId)>2)}" class=""  style="position: relative;" >
                            <router-link :to="`/products/${category.catUrlKey}`" class="nav-link cat_link"><span @click="refreshPageEvent(category)">{{category.catName}}</span></router-link>
                            <template v-if="findDepthOfCatTree(category.catId)==2">
                                    <span class="sub-toggle" :id="`sub_menu_link_${category.catId}`" @click="showSubCat(category.catId)"></span>
                            </template>
                            
                            <div class="mega-menu" :id="`sub_menu_${category.catId}`">
                                <div class="mega-menu__column" >
                                    <ul style="position: relative;">
                                    <template  v-for="cat in categoryList" :key="cat.catId">
                                        <li style="position: relative;">
                                        <template  v-if="cat.parentId ==category.catId">
                                                 <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link cat_link"><span @click="refreshPageEvent(cat)">{{cat.catName}}</span></router-link>
                                                         <template v-if="check3levelCat(cat.catId)">
                                                             <span class="sub-toggle" :id="`sub_menu_link_${cat.catId}`" @click="showSubCat(cat.catId)"></span>
                                                         </template>   
                                                    
                                                          <ul class="mega-menu__list" style="display: none;position: relative;" :id="`sub_menu_${cat.catId}`">
                                                                <template   v-for="cat1 in categoryList" :key="cat1.catId">
                                                                    <li v-if="cat.catId ==cat1.parentId">
                                                                        <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link cat_link"><span @click="refreshPageEvent(cat1)">{{cat1.catName}}</span></router-link>
                                                                
                                                                    </li>
                                                            </template>
                                                        </ul>
                                                    
                                       
                                        </template>
                                    
                                   
                                          </li>
                                    </template>
                                    </ul>
                                </div>
                            </div>
                      

                        </li>
                </template>
               
                
                
               
            </ul>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import i18next from 'i18next';
import Config from  '../config';
export default {
    props:[
        'maincategory',
        'categoryList'
    ],
    data(){
        return{
                translationloaded:false,
        }
    },
    watch:{
            fetchCurrentLag(lang){
            this.language =lang;
             this.enableTranslation();
        },
    },
    mounted(){
            this.language = this.fetchCurrentLag?this.fetchCurrentLag:Config.DEFAULT_LANG;
     this.enableTranslation();
    },
    
    methods: {
          enableTranslation(){
         let $this =this;

       
   fetch('/locales/translation.json').then(response => response.json()).then(data => {
      
                        i18next.init({
                                    lng: this.language,
                                    debug: true,
                                    resources: {
                                        en: {
                                        translation: data.en,
                                        },
                                        ar: {
                                        translation:  data.ar,
                                        }
                                    
                                    }
                                    
                                    }).then(function(t){                                    
                                        $this.translate=t;  
                                        $this.translationLoadCompleted();
                                                                            
                                      
                                    });
                });

         
        
    

    }, 
        translationLoadCompleted(){
        // this.switchLoginType('password');
         this.translationloaded=false;
         this.loadComponent =false;
                this.$nextTick().then(() => {
            // Add the component back in
            this.translationloaded=true;
            this.loadComponent =true;
        });

      


       
    },
               findDepthOfCatTree(catId){
                let treelength =0;
               
                 this.categoryList.forEach(function(item){
                  
                    if(item.catId ==catId){
                          var code = item.code;
                          let parts = code.split("#");
                          treelength = parts.length;
                       
                    }
                   
            });
            
            return treelength;
        },
         refreshPageEvent(cat){
            this.url_key = cat.catUrlKey;
             this.updateMetaDetails(cat);   
            this.$emit('refreshPageEvent',this.url_key)
            
        },
        updateMetaDetails(item){
                document.title = item.metaTitle??'';
                $('meta[name=description]').attr('content', item.shortDescription);
                $('meta[name=keywords]').attr('content', item.metaKeywords);
        },
        check3levelCat(parantCat){
              let isThirdLevel =false;          
                this.categoryList.forEach(function(item){                  
                        if(item.parentId ==parantCat){
                                isThirdLevel =true                    
                        }
                });
              return isThirdLevel;
        },       
        showSubCat(catId){
            if($(`#sub_menu_${catId}`).hasClass("show")){
                  $(`#sub_menu_${catId}`).removeClass('show');
                  $(`#sub_menu_${catId}`).css({'display':'none'})
                  $(`#sub_menu_link_${catId}`).removeClass('active');
            }else{
                 $(`#sub_menu_${catId}`).addClass('show');
                  $(`#sub_menu_${catId}`).css({'display':'block'});
                  $(`#sub_menu_link_${catId}`).addClass('active');
            }
           
          
        }
    },
}
</script>
<style scoped>
    .cat_close{
            top: 8px;
            right: 16px;
            position: absolute;
            z-index: 999;
            color: #fff;
            margin-right: 11px;
            margin-top: 7px;
            font-size: 17px;
            cursor: pointer;
    }
    .mega-menu{
        display: none;
    }

    .menu--mobile li .mega-menu:hover{
        display:block !important
    }
    .mega-menu > *{
        float:none!important
    }

    .mega-menu__list{
        max-width: none!important;
        max-height: none!important;
    }
    .menu--mobile .mega-menu__list > li {
    border-bottom: none!important;
    }
</style>