<template >
         <div class="modal fade" id="sign-up" tabindex="-1" role="dialog" aria-labelledby="sign-up" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
             <!-- Modal content-->
      <div class="modal-content" v-if="translationloaded==true">
        <span class="modal-close" data-dismiss="modal" @click="closeModdal"><i class="icon-cross2"></i></span>
        <div class="modal-body">
          
              <div class="signWrapperMainDiv">
              <div class="log_area">    
              <div class="login-box">
                  <template v-if="register_form.registration_status==false">
                         <h3>{{translate('signUp')}}</h3>
                        <p> {{translate('pleaseEnterYourDetails')}}</p>
                        <input type="text" id="login" :class="{error_border:register_form.first_name_error}" v-model="register_form.first_name" :placeholder="translate('YourFirstName')">
                        
                        <input type="text" id="login" :class="{error_border:register_form.last_name_error}" v-model="register_form.last_name" :placeholder="translate('YourLastName')">
                        
                        <div style="display:flex">
                                <div style="width:20%" class="mr-1">
                                    <input type="text"  id="login" v-model="country_code" readonly />
                                </div>
                                <div style="width:79%">
                                   <input type="text" id="login" :class="{error_border:register_form.phone_error}" v-model="register_form.phone" :placeholder="translate('EnterYourPhoneNumber')" @change="OnchangeUsername()">
                                </div>
                        </div>
                        <p class="text-danger" v-if="register_form.email_error">{{register_form.phone_error_msg}}</p>
                        <input type="text" id="login" :class="{error_border:register_form.email_error}" v-model="register_form.email" :placeholder="translate('EnterYourEmailId')" @change="OnchangeUsername()">
                        <p class="text-danger" v-if="register_form.email_error">{{register_form.email_error_msg}}</p>
                        <div class="password_group">
                          <input :type="passwordType" id="login" :class="{error_border:register_form.password_error}" v-model="register_form.password"  :placeholder="translate('ChangeYourPassword')"> 
                             <span class="password_eye" @click="togglePassWordType">
                                  <i :class="`fa ${(passwordType=='text'?'fa-eye':'fa-eye-slash')}`"></i>
                             </span>
                        </div>
                        <p class="text-danger" v-if="register_form.password_error">{{register_form.password_error_msg}}</p>
                        <div class="password_group">
                          <input :type="passwordType1" id="login" :class="{error_border:register_form.confirm_password_error}" v-model="register_form.confirm_password" :placeholder="translate('ConfirmPassword')"> 
                          <span class="password_eye" @click="toggleConfirmPassWordType">
                                  <i :class="`fa ${(passwordType1=='text'?'fa-eye':'fa-eye-slash')}`"></i>
                          </span>
                        </div>
                        <p class="text-danger" v-if="register_form.confirm_password_error">{{register_form.confirm_password_error_msg}}</p>
                        
                         <button class="srch_btn" type="submit" @click="registration()">
                                <template v-if="cart_ajax =='closed'">
                                    {{translate('submit')}}
                                   
                                </template>
                                <template v-if="cart_ajax =='open'">
                                <ButtonLoader
                                    :loaderColor="loaderColor"
                                    :loaderStyle="loaderStyle"
                                    :cart_ajax="cart_ajax"
                                >
                                </ButtonLoader>
                                </template>
                            </button>
                  </template>
                  <template v-else>
                       <h3> {{translate('verifyOtp')}}</h3>
                        <p>  {{translate('smsEmailWhatsAppWillGetACOnfirmationCode')}}</p>
                       <input type="text" id="login" :class="{error_border:verify_opt.confirm_password_error}" v-model="verify_opt.registration_otp" :placeholder="translate('enterOtp')"> 
                        <p class="text-danger" v-if="verify_opt.registration_otp_error">{{ translate(verify_opt.registration_otp_error_msg)}}</p>
                        <div class="srch_btn"><input type="submit" :value="translate('verifyOtp')" @click="verifyOTP()"></div>
                        <template v-if="verify_opt.otp_verify_status">
                            <div class="alert alert-success mt-4" role="alert">
                                <i class="fa fa-check-circle"></i>
                                {{translate('otpVerifiedSuccessfully')}}
                               
                            </div>
                        </template>
                  </template>
                   
              </div>
              
              <div class="signWrapperMain"><div class="login-bnr"><img src="/assets/img/sign-up-image.jpg"></div>
              </div>
              </div>
              </div>
              
          
        </div>
       
      </div>
        </div>
    </div>
</template>
<script>
import Config from  '@/config';
import axios from "axios";
import $ from 'jquery'
import ButtonLoader from "@/components/ButtonLoader";
import i18next from 'i18next';
import {mapGetters} from 'vuex';

export default {
    name:'Register',
    props:[
        'open_register'
    ],
    components:{
        ButtonLoader
    },
    watch:{
          open_register(registerStatus){
                if(registerStatus==false){
                    this.register_form.registration_status=false;
                }
          },
           fetchCurrentLag(lang){
            this.language =lang;
             this.enableTranslation();
         }  
    },
    data(){
        return {
            'register_form':{
                'first_name':'',
                'last_name':'',
                'email':'',
                'phone':'',
                'phone_error':'',
                'phone_error_msg':'',
                'password':'',
                'confirm_password':'',
                'first_name_error':'',
                'last_name_error':'',
                'email_error':'',
                'email_error_msg':'',
                'password_error':'',
                'password_error_msg':'',
                'confirm_password_error':'',
                'confirm_password_error_msg':'',                
                'registration_status':false
            },
            'registration_response':'',
            'verify_opt':{
                'registration_otp':'',
                'registration_otp_error':'',
                'registration_otp_error_msg':'',
                'otp_verify_status':false

            },
            'country_code':'',
            'errorStatus':false,
            'userChkError':'',
            passwordType:'password',
            passwordType1:"password",
            cart_ajax: "closed",
            loaderColor: "#fff",
            loaderStyle: {
                width: "35px",
            },
            'language':'',
            'translationloaded':false
        }
    },
    methods: {
        togglePassWordType(){
            if(this.passwordType =='password'){
               this.passwordType ='text'
           }else{
                this.passwordType ='password'
           }
        },
        toggleConfirmPassWordType(){
            if(this.passwordType1 =='password'){
               this.passwordType1 ='text'
           }else{
                this.passwordType1 ='password'
           }
        },
        registration(){
            this.errorStatus=false;
            this.reSetRegForm();
           
            if(this.register_form.first_name==''){
                this.register_form.first_name_error=true;
               this.errorStatus  =true;
            }else{
                 this.register_form.first_name_error=false;
            }
            if(this.register_form.last_name==''){
                this.register_form.last_name_error=true;
                this.errorStatus  =true;
            }else{
                 this.register_form.last_name_error=false;
            }
            if(this.register_form.phone==''){
                this.register_form.phone_error=true;
                this.errorStatus  =true;
                
            }else{
                let a = /^\d{9}$/; 
                 if (a.test(this.register_form.phone)) {
                      this.register_form.phone_error=false;
                       this.register_form.phone_error_msg='';
                 }else{
                      this.register_form.phone_error=true;
                      this.register_form.phone_error_msg='Invalid mobile number, Its length not matched';
                      this.errorStatus  =true;
                 }

                
            }

            if(this.register_form.email==''){
                this.register_form.email_error=true;
                 this.errorStatus  =true;
            }else{

                if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.register_form.email)){
                    this.register_form.email_error=false; 
                    this.register_form.email_error_msg='';                   
                }else{
                    this.register_form.email_error=true;
                     this.errorStatus  =true;
                     this.register_form.email_error_msg='Please enter a valid email address.';
                }
                 
            }

            if(this.register_form.password==''){
                this.register_form.password_error=true;
                 this.errorStatus  =true;
            }else{
                if(this.register_form.password.length <6){
                    this.register_form.password_error=true;
                    this.errorStatus  =true;
                    this.register_form.password_error_msg='Please enter at least 6 characters.';
                }else{
                     this.register_form.password_error_msg='';
                    this.register_form.password_error=false;
                }
                
            }
             if(this.register_form.password=='' ){
                this.register_form.confirm_password_error=true;
                this.errorStatus  =true;
            }else{
                if(this.register_form.password !=this.register_form.confirm_password){
                     this.register_form.confirm_password_error=true;
                      this.errorStatus  =true;
                      this.register_form.confirm_password_error_msg='Enter the same password';
                }else{
                     this.register_form.confirm_password_error=false;
                     this.register_form.confirm_password_error_msg='';
                }
                
            }

           

            if(this.errorStatus == false &&  this.cart_ajax=='closed'){
                 this.cart_ajax="open";
                 let $this =this;
                 let payload ={
                     "email":this.register_form.email,
                     "mobile":this.register_form.phone,
                     "name":`${this.register_form.first_name} ${this.register_form.last_name}`,
                     "password":this.register_form.password
                 };
                   axios.post(`${Config.BASE_URL}Customer/Register`,payload,Config.ADMIN_API_HEADER).then(function(response){                    
                       if(response.status==200){
                          // $this.$toast.success(`${response.data.Message}`);
                           $this.registration_response = response.data.Data;
                           $this.register_form.registration_status =true;

                               
                                   $this.register_form.first_name='';
                                   $this.register_form.last_name='';
                                   $this.register_form.email='';
                                   $this.register_form.phone='';
                                   $this.register_form.confirm_password='';
                                   $this.register_form.password='';
                                 
                                    
                                
                       }else{
                            $this.$toast.error(`Something went wrong please try again`);
                             $this.register_form.registration_status =false;
                            
                       }

                        $this.cart_ajax="closed";
                    
                   }).catch(function(error){
                        $this.$toast.error(`${error.response.data.Message}`);
                        $this.cart_ajax="closed";
                   });
            }else if(this.userChkError !=''){
                  this.$toast.error(`${this.userChkError}`);
                  this.cart_ajax="closed";
            }
        },
        verifyOTP(){
             let errorStatus= false;
            if(this.verify_opt.registration_otp==''){
                this.verify_opt.registration_otp_error=true;
                this.verify_opt.registration_otp_error_msg='Please enter valid OTP';
                errorStatus=true;
            }else{
                this.verify_opt.registration_otp_error=false;
                 this.verify_opt.registration_otp_error_msg='';
                  errorStatus=false;
            }

            if(errorStatus==false){                
                let otp_url_key =this.registration_response.otpUrlKey;
                  let $this =this;
                 axios.get(`${Config.BASE_URL}Customer/RegisterVerifyOTP?OTP=${$this.verify_opt.registration_otp}&OtpUrlKey=${otp_url_key}`,Config.ADMIN_API_HEADER).then(function(response){
                    if(response.status==200){
                         $this.$toast.success(`${response.data.Message}`);
                         $this.verify_opt.otp_verify_status=true;
                         $this.closeModdal();
                         $this.openLoginForm();

                    }
                 }).catch(function(error){
                       $this.verify_opt.registration_otp_error_msg = error.response.data.Message;
                       $this.verify_opt.registration_otp_error=true;
                       $this.verify_opt.otp_verify_status=false;
                 });
            }

        },
         closeModdal(){
                $('#sign-up').removeClass('show');
                $('#sign-up').css({'display':'none'});
                $('.ps-site-overlay').css({'visibility':'hidden','opacity':' 0'});
        },
        openLoginForm(){
               $('#sign-in').addClass('show');
                $('#sign-in').css({'display':'block'});
                $('.ps-site-overlay').css({'visibility':'visible','opacity':' .8'});
        },
        OnchangeUsername(){
            // let username ='';
            // if(type=='email'){
            //     username =this.register_form.email;
            // }else if(type =='phone'){
            //       username =this.register_form.phone
            // }
            let  $this =this;
             $this.userChkError='';
             $this.errorStatus = false;
             let checkingArr =['email','phone'];
             checkingArr.forEach(function(item){
                 if(item=='email' && $this.register_form.email!=''){
                          axios.get(`${Config.BASE_URL}Customer/UserExistCheck?UserName=${$this.register_form.email}`,Config.ADMIN_API_HEADER).then(function(response){
                            // console.log(response);
                            if(response.data.Data == true){
                                $this.$toast.error(`${response.data.Message}`);
                                $this.errorStatus = true;
                                $this.userChkError=response.data.Message;

                            }
                        });

                 }

                 if(item=='phone' && $this.register_form.phone!=''){
                          axios.get(`${Config.BASE_URL}Customer/UserExistCheck?UserName=${$this.register_form.phone}`,Config.ADMIN_API_HEADER).then(function(response){
                            // console.log(response);
                            if(response.data.Data == true){
                                $this.$toast.error(`${response.data.Message}`);
                                $this.errorStatus = true;
                                $this.userChkError=response.data.Message;

                            }
                        });

                 }

             });

          
        },
        reSetRegForm(){

                this.register_form.phone_error =false;
                this.register_form.phone_error_msg ='';
                this.register_form.first_name_error =false;
                this.register_form.last_name_error ='';
                this.register_form.email_error =false;
                this.register_form.email_error_msg='';
                this.register_form.password_error=false;
                this.register_form.confirm_password_error =false;
                this.register_form.confirm_password_error_msg ='';
                this.register_form.phone_error_msg ='';
              

        },
          enableTranslation(){
         let $this =this;

       
        fetch('/locales/translation.json').then(response => response.json()).then(data => {
            
                        i18next.init({
                                    lng: this.language,
                                    debug: true,
                                    resources: {
                                        en: {
                                        translation: data.en,
                                        },
                                        ar: {
                                        translation:  data.ar,
                                        }
                                    
                                    }
                                    
                                    }).then(function(t){                                    
                                        $this.translate=t;  
                                            $this.translationLoadCompleted();                                    
                                        
                                    });
                }); 

    },
        translationLoadCompleted(){       
                this.translationloaded=false;
                this.$nextTick().then(() => {            
                 this.translationloaded=true;
                });
                    
    }
    },
    computed:{
       ...mapGetters(['fetchCurrentLag']),
    },
    mounted(){
        this.language = this.fetchCurrentLag?this.fetchCurrentLag:Config.DEFAULT_LANG;
        this.enableTranslation();
        this.country_code = Config.COUNTRY_CODE;
    }
}
</script>
<style scoped>
.error_border{
    border: 1px solid red !important;
}
.login-bnr{
    padding-bottom: 20px !important;
}
button{
    background: #41b548;
    border: none;
    border-radius: 10px;
    color: #fff;
    text-transform: uppercase;
}
.srch_btn button {
    height: 100%;
    width: 100px;
    background: #41b548 !important;
    color: #fff;
    border-radius: 7px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.7px;
}

@media only screen and (max-width: 600px) {
#sign-up .modal-body {
     min-height: auto!important;
    height: 500px;
    overflow: auto!important;
}

.modal{
    top: -40px!important;
}
}


.password_group{
    position: relative;
}
.password_eye{
    position: absolute;
    right: 10px;
    top: 15px;
}
.arabic .password_eye{
    position: absolute;
    left: 10px;
    top: 15px;
    right: unset;
}
    
</style>