import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import helper from '@/helper/helper'
import $ from 'jquery';

const routes = [
  {
    path: '/',
    name: 'Home',      
    component: Home
  },
  {
    path: '/pages/:key',
    name: 'CmsPages',      
    component: () => import(/* webpackChunkName: "CmsPages" */ '../views/CmsPages.vue')
  },
  {
    path: '/item_not_found',
    name: 'ItemNotFound',      
    component: () => import(/* webpackChunkName: "ItemNotFound" */ '../views/Error_404.vue')
  },
  {
    path: '/about',
    name: 'About',
    beforeEnter :isSetLocation, 
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/terms-condition',
    name: 'TermsAndCondition',
    beforeEnter :isSetLocation, 
    component: () => import(/* webpackChunkName: "TermsAndCondition" */ '../views/TermsCondition.vue')
  },
  {
    path: '/return-policy',
    name: 'Returnpolicy',
    beforeEnter :isSetLocation, 
    component: () => import(/* webpackChunkName: "Returnpolicy" */ '../views/Returnpolicy.vue')
  },
  {
    path: '/user_licence_agrement',
    name: 'UserLicenceAgrement',
    beforeEnter :isSetLocation, 
    component: () => import(/* webpackChunkName: "UserLicenceAgrement" */ '../views/UserLicenceAgrement.vue')
  }
  ,{
    path: '/all-category',
    name: 'Morecategory',
    beforeEnter :isSetLocation, 
    component: () => import(/* webpackChunkName: "CategoryMore" */ '../views/MoreCategory.vue')
  },

  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    beforeEnter :isSetLocation, 
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/locations',
    name: 'Locations',   
    component: () => import(/* webpackChunkName: "LocationList" */ '../views/LocationList.vue'),
    
  },
  {
    path: '/products',
    name: 'ProductsList', 
    beforeEnter :isSetLocation,     
    component: () => import(/* webpackChunkName: "ProductsList" */ '../views/Products.vue')
  },
  {
    path: '/products/:category',
    name: 'Products',
    beforeEnter :isSetLocation,    
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  },
  {
    path: '/product-detail/:url_key',
    name: 'ProductDetail',
    beforeEnter :isSetLocation,     
    component: () => import(/* webpackChunkName: "product_details" */ '../views/ProductDetail.vue'),
    props: true
  },
  {
    path:'/cart',
    name:'CartFullView',
    beforeEnter :isSetLocation, 
    component:()=>import(/* webpackChunkName: "cart_full_view" */ '../views/CartFullView.vue'),
  },{
    path:'/checkout',
    name:'Checkout',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue'),
  },
  {
    path:'/order-success',
    name:'OrderSuccess',
    component:()=>import(/* webpackChunkName: "OrderSuccess" */ '../views/OrderSuccess.vue'),
  },
  {
    path:'/my-order',
    name:'MyOrder',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "MyAccount" */ '../views/MyOrder.vue'),
  },
  {
    path:'/my-address',
    name:'MyAddrss',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "MyAddress" */ '../views/MyAddress.vue'),
  },
  {
    path:'/my-wishlist',
    name:'MyWishlist',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "MyWishList" */ '../views/MyWishlist.vue'),
  },
  {
    path:'/my-profile',
    name:'MyProfile',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "MyProfile" */ '../views/MyProfile.vue'),
  },
  {
    path:'/my-coupons',
    name:'MyCoupons',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "MyCoupons" */ '../views/MyCoupon.vue'),
  },
  {
    path:'/my-cart',
    name:'MyCart',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "MyCart" */ '../views/MyCart.vue'),
  },
  {
    path:'/contact-us',
    name:'ContactUs',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs.vue'),
  },
  {
    path:'/change-password',
    name:'ChangePassword',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "ChangePassword" */ '../views/ChangePassword.vue'),
  },
  {
    path:'/contact-form',
    name:'ContactForm',  
    component:()=>import(/* webpackChunkName: "ContactForm" */ '../views/ContactForm.vue'),
  },
  {
    path:'/featured-product',
    name:'FeaturedProduct',
    beforeEnter :isSetLocation,
    component:()=>import(/* webpackChunkName: "FeaturedProduct" */ '../views/FeaturedProduct.vue'),
  },
  {
    path:'/best-sellers',
    name:'BestSellers',
    beforeEnter :isSetLocation,
    component:()=>import(/* webpackChunkName: "BestSellers" */ '../views/BestSellers.vue'),
  },
  {
    path:'/hot-deals',
    name:'HotDeals',
    beforeEnter :isSetLocation,
    component:()=>import(/* webpackChunkName: "HotDeals" */ '../views/HotDeals.vue'),
  },
  {
    path:'/recently-viewed-items',
    name:'RecentlyViewedItems',
    beforeEnter :isSetLocation,
    component:()=>import(/* webpackChunkName: "RecentlyViewedItems" */ '../views/RecentlyViewed.vue'),
  },
  {
    path:'/affiliation-program',
    name:'affiliationProgram',
    beforeEnter :isSetLocation,
    component:()=>import(/* webpackChunkName: "AffiliationProgram" */ '../views/AffiliationProgram.vue'),
  },
  {
    path:'/affiliation-earning',
    name:'AffiliationEarning',
    beforeEnter :isSetLocation,
    component:()=>import(/* webpackChunkName: "AffiliationEarning" */ '../views/AffiliationEarning.vue'),
  },
  {
    path:'/track',
    name:'',  
    component:()=>import(/* webpackChunkName: "Track" */ '../views/Track.vue'),
  },
  {
    path:'/share-banner',
    name:'ShareBanner',
    beforeEnter :isSetLocation,
    component:()=>import(/* webpackChunkName: "ShareBanner" */ '../views/ShareBanner.vue'),
  },
  {
    path:'/my-wallet',
    name:'MyWallet',
    beforeEnter :isAuthenticated,
    component:()=>import(/* webpackChunkName: "MyWallet" */ '../views/MyWallet.vue'),
  },
  
 
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

function isAuthenticated(to, from, next)
{
  
    if(helper.isLoginedUser() ==true){
      next();
    }else{
      $('#sign-in').addClass('show');
      $('#sign-in').css({'display':'block'});
      $('.ps-site-overlay').css({'visibility':'visible','opacity':' .8'});
    }
}

function isSetLocation(to, from, next){
  let locationInfo = localStorage.getItem('location_info');
  if(locationInfo){
    next();
  }else{
    next('/');
      // $('.srch').removeClass('error_border');     
      // $('#product-quickview').addClass('show');
      // $('#product-quickview').css({'display':'block'});
      // $('.ps-site-overlay').css({'visibility':'visible','opacity':' .8'});
  }
}

// function isLocationAutoSelectMatch(){
//   console.log(this.$store.getters('getAutoLocationNames'));
// }

router.beforeEach((to,from,next) => {
  // if(to.name !='ProductDetail'){
  //   // document.title = 'Khairath    ';
  //   // $('meta[name=description]').attr('content', 'Buy Groceries Online from Khairath   Supermarket  having a wide variety of fresh fruits & vegetables, Meat & Fish, baby care products, personal care products, Poultry products, Bakery Products at a discounted rate.');
  //   // $('meta[name=keywords]').attr('content', ' Khairath  , Khairath   Supermarket, Supermarket , Buy Groceries, Buy groceries online, Groceries Online, Discounted Rates, Online Grocery, Online Supermarket, order groceries online');
  //   // $("meta[property='og\\title']").attr('content', 'Khairath   Supermarket : Buy Groceries Online at Discounted Rates');
  //   // $("meta[property='og\\description']").attr('content', 'Buy Groceries Online from Khairath   Supermarket  having a wide variety of fresh fruits & vegetables, Meat & Fish, baby care products, personal care products, Poultry products, Bakery Products at a discounted rate.');
  //   // $("meta[property='og\\image']").attr('content', '/assets/img/Khairath  _Brand icon_.png');
  // }
  

  if (!to.matched.length) {
    next('/item_not_found');
  } else {
    next();
  }
})

export default router
