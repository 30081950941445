<template>

      <footer class="ps-footer" v-if="translationloaded==true">
      <div class="container"> 
          <div class="fooetrlinkAlldivBorder">    
           <div class="row">



            <div class="col-md-3">
            <template v-for="(cat,index) in maincategory" :key="index">
              <template v-if="index==0">
                      <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" style="padding:0px">
                                    <h4 @click="refreshPageEvent(cat)">{{cat.catName}}</h4>
                      </router-link>
                        <ul class="footer_ul">
                            <template  v-for="(cat1,index) in getCategoryList" :key="index" >
                                <li v-if="cat.catId==cat1.parentId && index">
                                    <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link"  style="padding:0px">
                                                    <span @click="refreshPageEvent(cat)">{{cat1.catName}}</span>
                                    </router-link>
                                </li>
                            </template>
                            
                        </ul> 
              </template>
               
             </template>   
               </div>
            <div class="col-md-3">
                 <template v-for="(cat,index) in maincategory" :key="index">
                        <template v-if="index==2">
                                
                                <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" style="padding:0px">
                                    <h4 @click="refreshPageEvent(cat)">{{cat.catName}}</h4>
                                </router-link>
                                
                               
                                    <ul class="footer_ul">
                                        <template  v-for="(cat1,index) in getCategoryList" :key="index" >
                                            <li v-if="cat.catId==cat1.parentId && index">
                                                <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link"  style="padding:0px">
                                                    <span @click="refreshPageEvent(cat)">{{cat1.catName}}</span>
                                                </router-link>
                                            </li>
                                        </template>
                                        
                                    </ul> 
                        </template>
                        
                    </template>  
               </div>
                   <div class="col-md-3">
            <template v-for="(cat,index) in maincategory" :key="index">
              <template v-if="index==3">
                      <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" style="padding:0px">
                                    <h4 @click="refreshPageEvent(cat)">{{cat.catName}}</h4>
                      </router-link>
                        <ul class="footer_ul">
                            <template  v-for="(cat1,index) in getCategoryList" :key="index" >
                                <li v-if="cat.catId==cat1.parentId && index">
                                    <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link"  style="padding:0px">
                                                    <span @click="refreshPageEvent(cat)">{{cat1.catName}}</span>
                                    </router-link>
                                </li>
                            </template>
                            
                        </ul> 
              </template>
               
             </template>   
               </div>
            <div class="col-md-3">
                    <template v-for="(cat,index) in maincategory" :key="index">
                        <template v-if="index==4">
                                
                                <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" style="padding:0px">
                                    <h4 @click="refreshPageEvent(cat)">{{cat.catName}}</h4>
                                </router-link>
                                
                               
                                    <ul class="footer_ul">
                                        <template  v-for="(cat1,index) in getCategoryList" :key="index" >
                                            <li v-if="cat.catId==cat1.parentId && index">
                                                <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link"  style="padding:0px">
                                                    <span @click="refreshPageEvent(cat)">{{cat1.catName}}</span>
                                                </router-link>
                                            </li>
                                        </template>
                                        
                                    </ul> 
                        </template>
                        
                    </template>  
               
            </div>
            
                
            <div class="col-md-3">
                    <template v-for="(cat,index) in maincategory" :key="index">
                        <template v-if="index==7">
                                 <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" style="padding:0px">
                                    <h4 @click="refreshPageEvent(cat)">{{cat.catName}}</h4>
                                </router-link>
                                    <ul class="footer_ul">
                                        <template  v-for="(cat1,index) in getCategoryList" :key="index" >
                                            <li v-if="cat.catId==cat1.parentId && index">
                                                <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link"  style="padding:0px">
                                                    <span @click="refreshPageEvent(cat)">{{cat1.catName}}</span>
                                                </router-link>
                                            </li>
                                        </template>
                                        
                                    </ul> 
                        </template>
                        
                    </template>  
               
               </div>

            <div class="col-md-3">
                    <template v-for="(cat,index) in maincategory" :key="index">
                        <template v-if="index==8">
                                
                                <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" style="padding:0px">
                                    <h4 @click="refreshPageEvent(cat)">{{cat.catName}}</h4>
                                </router-link>
                                
                               
                                    <ul class="footer_ul">
                                        <template  v-for="(cat1,key,index) in getCategoryList" :key="index" >
                                            <li v-if="cat.catId==cat1.parentId ">
                                                
                                               
                                                <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link"  style="padding:0px">
                                                    <span @click="refreshPageEvent(cat)">{{cat1.catName}}</span>
                                                </router-link>
                                            </li>
                                        </template>
                                        
                                    </ul> 
                        </template>
                        
                    </template> 
               </div>
            <div class="col-md-3">
                    <template v-for="(cat,index) in maincategory" :key="index">
                        <template v-if="index==9">
                                
                                <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" style="padding:0px">
                                    <h4 @click="refreshPageEvent(cat)">{{cat.catName}}</h4>
                                </router-link>
                                
                               
                                    <ul class="footer_ul">
                                        <template  v-for="(cat1,index) in getCategoryList" :key="index" >
                                            <li v-if="cat.catId==cat1.parentId && index">
                                                <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link"  style="padding:0px">
                                                    <span @click="refreshPageEvent(cat)">{{cat1.catName}}</span>
                                                </router-link>
                                            </li>
                                        </template>
                                        
                                    </ul> 
                        </template>
                        
                    </template>  
               
            </div>
            <!-- <div class="col-md-3">
                    <template v-for="(cat,index) in maincategory" :key="index">
                       
                        <template v-if="index==5">
                                 <router-link :to="`/products/${cat.catUrlKey}`" class="nav-link" style="padding:0px">
                                    <h4 @click="refreshPageEvent(cat)">{{cat.catName}}</h4>
                                </router-link>
                                    <ul class="footer_ul">
                                        <template  v-for="(cat1,index) in getCategoryList" :key="index" >
                                            <li v-if="cat.catId==cat1.parentId && index">
                                                <router-link :to="`/products/${cat1.catUrlKey}`" class="nav-link"  style="padding:0px">
                                                    <span @click="refreshPageEvent(cat)">{{cat1.catName}}</span>
                                                </router-link>
                                            </li>
                                        </template>
                                        
                                    </ul> 
                        </template>
                        
                    </template>  
               
               </div> -->
</div>
<router-link :to="`/all-category`" class="viewAllcategorymainBtn">{{translate('viewAllCategory')}}</router-link>

           </div>
      </div>
      <div class="footerBorder">
         <div class="container">     
          
          <div class="row address-sec">
              <div class="col-md-3">
 <a href="#" class="" ><img src="/assets/img/logo.png" alt="" ></a>
               
               
               </div>
              <div class="col-md-3">
              <p>{{translate('effortlessShopping')}}</p>
              <h3 class="footertxtDownload">{{translate('downloadApp')}}</h3>
                <div class="payment-app-img">
                    <a href="https://play.google.com/store/apps/details?id=com.khairatlebanon.customerapp"><img src="/assets/img/google-play.png"></a>
                    <a href="https://apps.apple.com/app/khairat-libnan/id1631272585"><img src="/assets/img/app-store.png"></a>
                     
                    
                    </div>
                <div class="social_link_box">
                        <ul class="social_links">
                            <li>
                                <a target="_blank" href="https://www.facebook.com/Khairatlebanon"><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                            </li>
                             <li>
                                <a target="_blank" href="https://www.snapchat.com/add/khairatlebanon1"><i class="fa fa-snapchat-square" aria-hidden="true"></i></a>
                            </li>
                             <li>
                                <a target="_blank" href="https://youtube.com/channel/UCRAMqFuQUZL1se6M6HAkJgw"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                            </li>
                             <li>
                                <a target="_blank" href="https://instagram.com/khairatlebanon"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a target="_blank" href="https://vm.tiktok.com/ZSe8TYqf8/"><svg style="width: 14px;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="tiktok" class="svg-inline--fa fa-tiktok fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg></a>
                            </li>
                        </ul>
                    </div>
               </div>
              <div class="col-md-3">
                 <h4>{{translate('quikLinks')}}</h4>
                  <ul class="footer_ul">
                      <template v-for="(cmspage,index) in getStoreCmsPages" :key="index">
                            <li>
                            
                              <router-link :to="`/pages/${cmspage.pageKey}`">{{translate(cmspage.title)}}</router-link>
                            </li>
                      </template>
                    
                      
                  </ul>    
               
               </div>
             <div class="col-md-3">
                        <h4>{{translate('letUsHelpYou')}}</h4>
               <ul>
                    <li>                                          
                       <router-link :to="`/my-profile`">{{translate('yourAccount')}}</router-link>
                    </li>                 
                   <li>
                     <a  href="/privacy_policy">{{translate('privacypolicy')}}</a>   
                        <!-- <router-link :to="`/privacy_policy`" @click="scrolltotop()">{{translate('privacypolicy')}}</router-link> -->
                    </li>
                   <li>
                     <a  href="/terms-condition">{{translate('termsConditions')}}</a>  
                        <!-- <router-link :to="`/terms-condition`" @click="scrolltotop()">{{translate('termsConditions')}}</router-link> -->
                    </li>
                   <li>
                     <a  href="/return-policy">{{translate('cancelationAndRefundPolicy')}}</a>  
                       <!-- <router-link :to="`/return-policy`" @click="scrolltotop()">{{translate('cancelationAndRefundPolicy')}}</router-link> -->
                     </li>
                    
                    <li>
                         <a  href="/contact-form">{{translate('contactUs')}}</a>  
                       <!-- <router-link :to="`/contact-form`" @click="scrolltotop()">{{translate('contactUs')}}</router-link> -->
                     </li>                  
    
               </ul>
               
               </div>
          </div>
          
            
           
        </div>
           </div>







            <div class="ps-footer__copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-7">
                <p>{{translate('khairathLebanonCopyright')}}© {{new Date().getFullYear()}} {{translate('copyrightAllRightsReservedDesignedBy')}}<a class="ml-0" target="_blank" href="https://doukani.com">Doukani.com</a> </p>
                    </div>
                        <div class="col-sm-5">
         
                                <ul class="footerPaymentMethodNav">
                                    <li>    <p> {{translate('weAcceptPaymentMethod')}}</p></li>
                                    <li> <img src="/assets/img/payment-method/5.jpg"></li>
                                        <li> <img src="/assets/img/payment-method/3.jpg"></li>
                                        
                                </ul>

                                
                    </div>
                     </div>
            </div>
             </div>
    </footer>
    <div class="ps-site-overlay"></div>
 
</template>
<script>
import helper from "@/helper/helper";
import {mapGetters,mapActions} from 'vuex';
import $ from 'jquery';
export default {
    name: 'Footer',
    props:[
        'translationloaded',
        'translate'
    ],
    data(){
        return {
            
             maincategory:[],
             topBrands:[]
        }
       
    },
    computed:{
            ...mapGetters(['getCategoryList','getPopularProducts','getStoreCmsPages']),
           
    },
    watch:{
        getCategoryList(){
            this.filterMainCategory()
        },
       

       
    },
     methods:{

         ...mapActions(['getTopBrandList','storeCmsPages']),
      
                 filterMainCategory(){
                    let mainCatArr =[];
                    let mainCatArrMob=[];
                    let mainCatCnt =0;           
                    this.getCategoryList.forEach(function(item){
                    
                            if(item.parentId ==1 && mainCatCnt <6){
                                mainCatArr.push(item); 
                                mainCatCnt++;
                            }
                            if(item.parentId ==1){
                                mainCatArrMob.push(item);
                            }
                    });
                    this.maincategory=mainCatArrMob;
                    
                   
                },
                refreshPageEvent(cat){                    
                    this.url_key = cat.catUrlKey;      
                    this.updateMetaDetails(cat);      
                    this.$emit('refreshpage',this.url_key)
            
                },
                updateMetaDetails(item){
                    document.title = item.metaTitle??'Khairath Supermarket';
                    $('meta[name=description]').attr('content', item.shortDescription);
                    $('meta[name=keywords]').attr('content', item.metaKeywords);
                }
        },
        scrolltotop(){
            alert("hi");
                 helper.backtoTop();
        },
        mounted(){
            let $this =this;
            $this.getTopBrandList().then(function(response){
                    $this.topBrands =response.data.Data;
            });
            $this.storeCmsPages();
        }
}
</script>
<style scoped>
.ps-footer__copyright p{
    font-weight: 300!important;
}
.footer_ul{
    max-height: 134px;
    overflow: hidden;
}


.ps-footer h4 {
   
    margin-top: 20px;
}
.main_cat{
     font-weight: bold;

    padding-right: 5px;
    color:#000;
}
.main_cat span{
    font-weight: bold;

    padding-right: 5px;
    color:#000;
}
.brand_items{
    margin-right: 5px;
    font-weight: bold;
   
}
.router-link-active{
    display: inline-block;
}
.social_links{
   width:100%
}
.social_links li{    
    display: inline-block!important;
    padding-right: 10px;
    font-size: 24px;
    padding-top: 10px;
}
.fa-facebook-square{
    color: #3a5794
}
.fa-youtube{
    color: #FF0000
}
.fa-snapchat-square{
    color:#FEFC00
}
.fa-instagram{
   background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
          /* Also define standard property for compatibility */
          background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>