<template >
    <div class="text-center">
        <img src="/assets/img/no-cart-product.png" />
    </div>
</template>
<script>
export default {
    name:'EmptyItem'
}
</script>
<style scoped>
    
</style>