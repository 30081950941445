<template >
      <div class="modal fade" id="sign-in" tabindex="-1" role="dialog" aria-labelledby="sign-in" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
             <!-- Modal content-->
      <div class="modal-content">
        <span class="modal-close" data-dismiss="modal" @click="closeLoginModdal"><i id="icon-cross2" class="icon-cross2"></i></span>
        <div class="modal-body">
          <div class="row">
              <div class="col-md-6">
                  <div class="login-bnr">
                      <template v-if="getLoginPopupData?.imageUrl">
                         
                          <img :src="`${web_admin_url}${getLoginPopupData.imageUrl}`">
                      </template >
                      <template v-else>
                          <img src="/assets/img/sign-up-image.jpg">
                      </template>
                      
                      </div>
                  </div>
              <div class="col-md-6">
              <div class="log_area">    
              <div class="login-box">
                

                  <template v-if="translationloaded==true &&  loginType=='otp'">
                        <template v-if="otp_login.login_otp_send_status==false">
                            <h3>{{translate('login')}}</h3>
                            
                                <p>{{translate('thisPhoneEmailWhatsAppWillgetConfirmCode')}} </p>
                                <input type="text" id="login" :class="{error_border:otp_login.user_name_error}" v-model="otp_login.username" :placeholder="translate('typeYourMobileNumberEmail')">
                                <div class="srch_btn"> 
                                            <button class="srch_btn" type="submit"  @click="otpLogin()">                               
                                                <template v-if="cart_ajax =='closed'">
                                                    {{translate('submit')}}
                                                </template>
                                                <template v-if="cart_ajax =='open'">
                                                <ButtonLoader
                                                    :loaderColor="loaderColor"
                                                    :loaderStyle="loaderStyle"
                                                    :cart_ajax="cart_ajax"
                                                >
                                                </ButtonLoader>
                                                </template>
                                            </button>
                                    
                                    </div>

                                    

                        </template>
                        <template  v-if="otp_login.login_otp_send_status==true">
                                <h3>{{translate('verifyOtp')}}</h3>
                            
                                <input type="text" id="login" :class="{error_border:otp_login.verify_otp_error_msg}" v-model="otp_login.login_otp" placeholder="Please enter OTP">
                                <div class="srch_btn"> 
                                            <button class="srch_btn" type="submit"  @click="verifyLoginOtp()">                               
                                                <template v-if="cart_ajax =='closed'">
                                                   {{translate('verify')}}
                                                </template>
                                                <template v-if="cart_ajax =='open'">
                                                <ButtonLoader
                                                    :loaderColor="loaderColor"
                                                    :loaderStyle="loaderStyle"
                                                    :cart_ajax="cart_ajax"
                                                >
                                                </ButtonLoader>
                                                </template>
                                            </button>
                                    
                                    </div>
                                    <template v-if="otp_login.login_otp_send_status==true">
                                        <div class="login_reset_link">
                                            <a href="javascript:void(0)" @click="resentLoginOtp()">{{translate('resentOTP')}}</a>
                                        </div>
                                        

                                    </template>
                        </template>

                  </template>
                
                  <template v-if="translationloaded==true && loginType=='password'">
                         <h3>
                             {{ translate('login') }}
                             </h3>
                        <p>
                            {{ translate('emailMobileNumber')}} 
                            </p>
                        <input type="text" id="login" :class="{error_border:login_form.user_name_error}" v-model="login_form.user_name" :placeholder="`${translate('emailMobileNumber')} ( ${country_code} ) 123XXXXXX`">
                        <p>
                            {{translate('password')}}
                            </p>
                        <div class="password_group">
                             <input :type="passwordType" id="login" :class="{error_border:login_form.password_error}" v-model="login_form.password" :placeholder="translate('password')">
                             <span class="password_eye" @click="togglePassWordType">
                                  <i :class="`fa ${(passwordType=='text'?'fa-eye':'fa-eye-slash')}`"></i>
                             </span>
                           
                        </div>    
                       
                        <div class="srch_btn"> 
                                    <button class="srch_btn" type="submit"  @click="login()">                               
                                        <template v-if="cart_ajax =='closed'">
                                            {{translate('submit')}}
                                        </template>
                                        <template v-if="cart_ajax =='open'">
                                        <ButtonLoader
                                            :loaderColor="loaderColor"
                                            :loaderStyle="loaderStyle"
                                            :cart_ajax="cart_ajax"
                                        >
                                        </ButtonLoader>
                                        </template>
                                    </button>
                            
                            </div>

                  </template>


              
           
                  <template v-if="login_form.login_error_status==true">
                    <div class="alert alert-danger mt-4" role="alert">
                       {{login_form.login_error_msg}}
                      </div>
                  </template>
                  <template v-if="otp_login.verify_otp_error_status==true">
                       <div class="alert alert-danger mt-4" role="alert">
                            {{otp_login.verify_otp_error_msg}}
                       </div>
                  </template>
                     <template v-if="otp_login.login_otp_error_status==true">
                       <div class="alert alert-danger mt-4" role="alert">
                            {{otp_login.login_otp_error_msg}}
                       </div>
                  </template>
                  <div class="w-100 text-center mt-2" v-if="translationloaded==true">
                        <a href="javascript:void(0)" @click="Registerpopup()" class="text-warning mr-1">
                            {{translate('register')}} 
                             </a>

                       <a href="javascript:void(0)" @click="passwordReset()"  >
                           {{translate('forgotPassword')}}
                           </a>
                       <p> {{translate('or')}}</p>
                       <a href="javascript:void(0)" class="text-warning" @click="switchLoginType(loginType=='otp'?'password':'otp')">
                           {{translate('LoginWith')}}
                            {{loginType=='otp'?translate('password'):translate('otp')}}</a>
                      
                  </div>
                 
                 
              </div>
              </div>
              </div>
            </div>
        </div>
       
      </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import {mapGetters,mapActions} from 'vuex';
import ButtonLoader from "../components/ButtonLoader";
import i18next from 'i18next';
import Config from  '@/config';


export default {
    name:'Login',
    watch:{
        fetchCurrentLag(lang){
            this.language =lang;
             this.enableTranslation();
        }
    },
    data(){
      return { 
         'country_code':'', 
         'web_admin_url' :'',      
        'login_form':{
          'user_name':'',
          'user_name_error':'',
          'user_name_error_msg':'',
          'password':'',
          'password_error':'',
          'password_error_msg':'',
          'login_error_status':false,
          'login_error_msg':'',
        
      },
      cart_ajax:"closed",
      loaderColor: "#fff",
      loaderStyle: {
          width: "35px",
      },
      otp_login:{
          'username':'',
          'user_name_error':'',
          'user_name_error_msg':'',
          'login_otp_error_status':'',
          'login_otp_error_msg':'',
          'login_otp_url_key':'',
          'login_otp_send_status':false,
          'login_otp':'',
          'verify_otp_error_status':false,
          'verify_otp_error_msg':''



      },
      loginType:'password',
      'translate':'',
      'translationloaded':false,
      'language':'',
      'passwordType':'password'


      }
    },
      components:{
        ButtonLoader
    },
    methods:{
       ...mapActions(['customerLogin','customerOtpLogin','customerLoginOtpVerify']),
       switchLoginType(type){
           this.loginType =type;
           this.translationloaded=true;
       },
       togglePassWordType(){

           if(this.passwordType =='password'){
               this.passwordType ='text'
           }else{
                this.passwordType ='password'
           }

       },
      otpLogin(){
           let errorStatus=false;
            if(this.otp_login.username==''){
                this.otp_login.user_name_error=true;
                errorStatus=true;
                this.otp_login.user_name_error_msg='Email /mobile number filed required';
            }else{
                this.otp_login.user_name_error=false;
                this.otp_login.user_name_error_msg='';      
            }

            if(errorStatus==false){

                      this.cart_ajax="open";
          let $this =this;
          let payload ={
               "userName":$this.otp_login.username,
              
          };
           $this.customerOtpLogin(payload).then(function(response){ 
                    $this.cart_ajax="closed";            
                        if(response.status ==200){
                            $this.otp_login.login_otp_error_status =false;
                            $this.otp_login.login_otp_url_key =response.data.Data;
                            $this.otp_login.login_otp_send_status =true;
                            $this.$toast.success(`${response.data.Message}`);
                           
                        }
                    }).catch(function(error){    
                        $this.cart_ajax="closed";   
                           
                        $this.otp_login.login_otp_error_status =true;
                        $this.otp_login.login_otp_error_msg =error.response.data.Message;
                    });

            }
      }, 

      verifyLoginOtp(){
            let errorStatus=false;
            if(this.otp_login.login_otp==''){
                this.otp_login.verify_otp_error_status=true;
                errorStatus=true;
                this.otp_login.verify_otp_error_msg='Email /mobile number filed required';
            }else{
                this.otp_login.verify_otp_error_status=false;
                this.otp_login.verify_otp_error_msg='';      
            }

            if(errorStatus==false){

            this.cart_ajax="open";
          let $this =this;
          let payload ={
               "otp":$this.otp_login.login_otp,
               "otp_url":$this.otp_login.login_otp_url_key
              
          };
           $this.customerLoginOtpVerify(payload).then(function(response){ 
                    $this.cart_ajax="closed";            
                        if(response.status ==200){
                            $this.otp_login.verify_otp_error_status =false; 
                            $this.$toast.success(`${response.data.Message}`);
                            window.location.reload();
                           
                        }
                    }).catch(function(error){    
                        $this.cart_ajax="closed";   
                           
                        $this.otp_login.verify_otp_error_status =true;
                        $this.otp_login.verify_otp_error_msg =error.response.data.Message;
                    });

            }
      },
      resentLoginOtp(){
         let $this =this;
          let payload ={
               "userName":$this.otp_login.username,
              
          };
           $this.customerOtpLogin(payload).then(function(response){ 
                    $this.cart_ajax="closed";            
                        if(response.status ==200){
                            $this.otp_login.login_otp_error_status =false;
                            $this.otp_login.login_otp_url_key =response.data.Data;
                            $this.otp_login.login_otp_send_status =true;
                            $this.$toast.success(`${response.data.Message}`);
                           
                        }
                    }).catch(function(error){    
                        $this.cart_ajax="closed";   
                           
                        $this.otp_login.login_otp_error_status =true;
                        $this.otp_login.login_otp_error_msg =error.response.data.Message;
                    });

            
      },

      login(){
        
        let errorStatus=false;
        if(this.login_form.user_name==''){
            this.login_form.user_name_error=true;
            errorStatus=true;
            this.login_form.user_name_error_msg='Email /mobile number filed required';
        }else{
               this.login_form.user_name_error=false;
               this.login_form.user_name_error_msg='';      
        }

        if(this.login_form.password==''){
            this.login_form.password_error=true;
            errorStatus=true;
            this.login_form.password_error_msg='Password filed required';
        }else{
               this.login_form.password_error=false;
               this.login_form.password_error_msg='';      
        }

        if(errorStatus==false){
           this.cart_ajax="open";
          let $this =this;
          let payload ={
               "userName":this.login_form.user_name,
               "password":this.login_form.password
          };
           $this.customerLogin(payload).then(function(response){ 
              $this.cart_ajax="closed";            
              if(response.status ==200){
                  $this.login_form.login_error_status ==false;
                   $this.$toast.success(`successfully logined`);
                   window.location.reload();
                  // console.log(response.data)
              }
           }).catch(function(error){    
              $this.cart_ajax="closed";   
            //  console.log(error);
            //    console.log(error.response.data.Message);       
               $this.login_form.login_error_status =true;
               $this.login_form.login_error_msg =error.response.data.Message;
           });
        }


      },
      passwordReset(){
         this.closeLoginModdal();
          $('#forget-password').addClass('show');
         $('#forget-password').css({'display':'block'});
         $('.ps-site-overlay').css({'visibility':'visible','opacity':' .8'});
         $('#sign-in').attr("aria-hidden",true)
         
      },
      Registerpopup(){
      
         this.$emit('open_register_popup',true)
         $('#icon-cross2').trigger('click');
          $('#sign-up').addClass('show');
         $('#sign-up').css({'display':'block'});
         $('.ps-site-overlay').css({'visibility':'visible','opacity':' .8'});
          // $('.login-box input').removeClass('error_border');
          $('.login-box .text-danger').html('');
      },
         closeLoginModdal(){
            $('#sign-in').removeClass('show');
            $('#sign-in').css({'display':'none'});
            $('.ps-site-overlay').css({'visibility':'hidden','opacity':' 0'});
        },

     enableTranslation(){
         let $this =this;

       
   fetch('/locales/translation.json').then(response => response.json()).then(data => {
      
                        i18next.init({
                                    lng: this.language,
                                    debug: true,
                                    resources: {
                                        en: {
                                        translation: data.en,
                                        },
                                        ar: {
                                        translation:  data.ar,
                                        }
                                    
                                    }
                                    
                                    }).then(function(t){                                    
                                        $this.translate=t;  
                                        $this.translationLoadCompleted();                                    
                                      
                                    });
                });

         
        
    

    }, 
    translationLoadCompleted(){
        // this.switchLoginType('password');
         this.translationloaded=false;
                this.$nextTick().then(() => {
            // Add the component back in
            this.translationloaded=true;
        });
       
    },
     
      
    },computed:{
       ...mapGetters(['getCustomerInfo','isCustomerLoggined','fetchCurrentLag','getLoginPopupData']),
      
    },
    mounted(){
        this.language = this.fetchCurrentLag?this.fetchCurrentLag:Config.DEFAULT_LANG;
        this.enableTranslation();
        this.web_admin_url = Config.WEB_ADMIN_URL;
        this.country_code = Config.COUNTRY_CODE;
        
    }
}
</script>
<style scoped>
    @media only screen and (max-width: 600px) {
      
        #sign-in .modal-close{
            top:20px!important
        }

        .modal{
            top: -40px!important;
        }
    }

 
    .error_border{
      border: 1px solid red !important;
    }
    button{
    background: #2289C9!important;
    border: none;
    border-radius: 5px!important;
    color: #fff;
    text-transform: uppercase;
}
.srch_btn button {
    height: 100%;
   
    background: #2289C9  !important;
    color: #fff;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    
}
.login_reset_link{
    text-align: center;
    padding: 10px;
}
.password_group{
    position: relative;
}
.password_eye{
    position: absolute;
    right: 10px;
    top: 15px;
}
.arabic .password_eye{
    position: absolute;
    left: 10px;
    top: 15px;
    right: unset;
}
</style>