<template>
    <Header @refreshpage="loadPage($event)" :cartData="cart_info" 
     @cartUpdated="cartUpdated($event)"
       @cartSummaryUpdated="cartSummaryUpdates($event)"
       @location_text_updated ="location_refresh"
       @search_trigger="searchProduct"
       :locationupdated="locationupdated"
       >
    </Header>  
     <router-view :url_change="pageReload"
          :changed_url="url_key"
          :customerCartData="cart_info"
          :cart_summary="cart_summary"
           @cartUpdated="cartUpdated($event)"
          :search_string="searchString"
           @refreshpage="loadPage($event)" 
           @location_updates="locationUpdated"
          :locationupdated="locationupdated"
          :translationloaded="translationloaded"
          :translate="translate"
          v-if="loadComponent && translationloaded"
          
        />
     <Footer  @refreshpage="loadPage($event)" 
      :translationloaded="translationloaded"
      :translate="translate">
      </Footer> 
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import i18next from 'i18next';
import Config from  '@/config';
import {mapGetters} from 'vuex';
export default {
   components: {
        Header,
        Footer
    },
     watch:{
        fetchCurrentLag(lang){
            this.language =lang;
             this.enableTranslation();
        }
    },
    computed:{
       ...mapGetters(['fetchCurrentLag']),
    },
    
    data(){
      return {
        pageReload:'',
        url_key:'',
        cart_info:[],
        cart_summary:[],
        searchString:'',
        locationupdated:false,
        translate:'',
        translationloaded:false,
        loadComponent:true
      }
    },methods:{
        loadPage(urlkey){
         
          this.url_key =urlkey;
          this.pageReload=Math.floor(Math.random() * Math.floor(1000));
        },
        generateGestCartId(){
          let guestid ='';
          guestid =localStorage.getItem("guestId");
          if(guestid ==null){
             guestid = Math.random().toString(36).substring(2,9);
             localStorage.setItem("guestId", guestid);
          }
        },
        cartUpdated(cart_Data){           
            this.cart_info = cart_Data;
        },
        cartSummaryUpdates(cartSummaryData){
          
          this.cart_summary = cartSummaryData;
        },
        searchProduct(searchString){
          this.searchString=searchString;
        },
        locationUpdated(){     
           
            this.locationupdated=true;
        },
        location_refresh(){
          let $this =this;
          setTimeout(function(){
              $this.locationupdated=false;
          });
          
         
        },
          enableTranslation(){
                let $this =this; 
              fetch('/locales/translation.json').then(response => response.json()).then(data => {
                  
                                    i18next.init({
                                                lng: this.language,
                                                debug: true,
                                                resources: {
                                                    en: {
                                                    translation: data.en,
                                                    },
                                                    ar: {
                                                    translation:  data.ar,
                                                    }
                                                
                                                }
                                                
                                                }).then(function(t){                                    
                                                    $this.translate=t;  
                                                    $this.translationLoadCompleted();                                    
                                                  
                                                });
                            });
    }, 
    translationLoadCompleted(){      
         this.translationloaded=false;
         this.loadComponent =false;
           this.$nextTick().then(() => {          
            this.translationloaded=true;
            this.loadComponent =true;
        });

      


       
    }

    },mounted(){
        this.generateGestCartId();
        let $this =this;
        let guestid=localStorage.getItem("guestId");
        let custid=localStorage.getItem("custId");
        let payload ={
          'cusId':custid,
          'custId':custid,
          'guestId':(custid==null?guestid:''),
          'vendorUrlkey': 'khairath'         
        };
        $this.$store.dispatch("getCart",payload).then(function(){
                    $this.cart_info = $this.$store.state.customer_cart;
                   
                   
        });

       let uri = window.location.search.substring(1); 
       let params = new URLSearchParams(uri);     
       $this.searchString=params.get("search");
       $this.$store.dispatch("getCustomerWishlist",payload);

       this.language = this.fetchCurrentLag?this.fetchCurrentLag:Config.DEFAULT_LANG;
       this.enableTranslation();

         
        
    },
     updated () {
        if( this.language == "ar")
        {
          document.title =  "اطلب فواكه وخضروات طازجة عبر الإنترنت | خيرات لبنان";
              
          }
          else {
               
                document.title =  "Order Fresh Fruits and Vegetables Online | Khairath Lebanon";
          }
        }
  }
</script>
 
