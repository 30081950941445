<template >

         <div class="product_qty" v-if="count>-1" >
            <div class="form-group--number" :style="style">
            <button class="up"  @click="add()">
                <i class="fa fa-plus" aria-hidden="true"  ></i></button>
                <button class="down" @click="sub()">
                <i class="fa fa-minus" aria-hidden="true"></i>
                </button>
                <input class="form-control"  type="text" readonly  v-model="count" placeholder="1"/>
            </div>
        </div>
  
</template>
<script>
export default {
    name:'cartQtyCounter',
    props:[
        'product_url_key',
        'cartItemId',
        'qtyCont',
        'qtyReset',
        'style'
    ],
    watch:{
        qtyCont(newCount){
            this.count =(newCount>0?newCount:1);
        },
        qtyReset(count){
            if(count!=0){
                if(this.count>1){
                    this.count= this.count-count;
                }
                
                
                this.$emit('carQtyReset');
            }

             
        }
    },
    data(){
        return {
            'count':(this.qtyCont>0?this.qtyCont:0)
        }
    },methods:{
        add(){
            this.count++;  
            this.updateCartQuntity('add');         
        },
        sub(){
            if(this.count>1){
              
                this.count--;
            }else{
                
                 this.count =1;
            }
            this.updateCartQuntity('sub');              
        },
        updateCartQuntity(type){  
                      
            let cartItemInfo ={
                'product_qty':this.count,
                'url_key':this.product_url_key,
                'type':type,
                'cart_item_id':this.cartItemId               
            }
            if(this.count >0){
                this.$emit('updateCartQty',cartItemInfo);
            }
            
        }
    }
}
</script>
<style scoped>
    
</style>