let vendorUrlKey = 'khairath';
let pincodeid = '';
let locationInfo = JSON.parse(localStorage.getItem('location_info'));

if (locationInfo) {
    vendorUrlKey = locationInfo.vendorUrlKey;
    pincodeid = locationInfo.pincodeId;
}


export default {


    BASE_URL :'https://wpr.intertoons.net/khairatapi/api/v2/',
    // BASE_URL :'https://api.khairatlebanon.com/api/v2/',   
    SITE_URL :'https://khairatlebanon.com/',
    WEB_ADMIN_URL: 'https://cdn.khairatlebanon.com/',
    BASE_URL1: 'https://api.khairatlebanon.com/api/v2/',
    BASE_URL3: 'https://api.khairatlebanon.com/v3/',



    CURRECNCY: 'AED ',
    STORE_NAME: 'Khairath',
    PINCODE_ID: pincodeid,
    VENDOR_URL_KEY: vendorUrlKey,
    GOOGLE_MAPS_API_KEY: 'AIzaSyD8L237G-BLrf0hQtqaTdTiezwwqEzoq4A',
    ADMIN_API_HEADER: {
        headers: {
            'vendorUrlKey': vendorUrlKey,
            'token': '',
            'lang': (localStorage.getItem('lang') == 'ar' ? 1 : 2)
        }
    },
    CUSTOMER_API_HEADER: {
        headers: {
            'vendorUrlKey': vendorUrlKey,
            'token': localStorage.getItem('customer_token'),
            'lang': (localStorage.getItem('lang') == 'ar' ? 1 : 2)
        }
    },
    COUNTRY_CODE: '+971',
    DEFAULT_LANG: localStorage.getItem('lang')

}