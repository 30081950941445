<template>
  <span v-if="product.dealTo" class="endSaleBgsecTxt">
      <template v-if="displayDays<1">
           {{translate('endIn')}} {{displayHours}}:{{displayMinuts}}:{{displaySeconds}}
      </template>
      <template v-else>
           {{translate('endOn')}} {{formatedDate}}
      </template>
     </span> 
</template>

<script>
import moment from 'moment'
export default {
    name:'ProductTimer',
  
    props:[
        'product',
        'translationloaded',
        'translate'
    ],
    data(){
        return {
            'displayDays':0,
            'displayHours':0,
            'displayMinuts':0,
            'displaySeconds':0,
            timer:''
        }
    },
    computed:{
        _seconds:()=>1000,
        _minutes(){
            return this._seconds * 60;
        },
        _hours(){
            return this._minutes * 60;
        },
        _days(){
            return this._hours * 24;
        },
        formatedDate(){
            let formatedDate ='';
            if(this.product.dealTo!='' ){
             
                 formatedDate = moment(this.product.dealTo).format('MMMM D');
            }
            return formatedDate;
          
        },
    },
    mounted(){
        this.showTimer();
    },
    unmounted(){
         clearInterval(this.timer);
    },   
    methods:{
       showTimer(){
           let $this =this;
           this.timer =setInterval(()=>{
            //    const now =new Date($this.product.currentArabTime);
               const now =new Date(new Date().toLocaleString("en-US", {timeZone: "Asia/Muscat"}));
               const end =new Date($this.product.dealTo);
               const distance =end.getTime()-now.getTime();
               
               if(distance< 0){
                   clearInterval($this.timer);
                   return;
               }

               const days = Math.floor(distance / $this._days);
               const hours = Math.floor((distance % $this._days)/$this._hours);
               const minutes = Math.floor((distance % $this._hours)/$this._minutes);
               const seconds = Math.floor((distance % $this._minutes)/$this._seconds);

               $this.displayMinuts = minutes < 10 ?"0"+ minutes:minutes;
               $this.displaySeconds = seconds < 10 ?"0"+ seconds:seconds;
               $this.displayHours = hours < 10 ?"0"+ hours:hours;
               $this.displayDays = days < 10 ?"0"+ days:days;

            //    console.log(`${ $this.displayDays}:${$this.displayHours}:${ $this.displayMinuts}:${$this.displaySeconds}`);

           },1000);
       }
    }
}
</script>

<style>

</style>