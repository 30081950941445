<template>
  <div id="homepage-3">
    <template v-if="getHomeBanner != ''">
      <HomeBanner
        :banners="getHomeBanner"
        :mobBanners="getHomeMobBanners"
      ></HomeBanner>
    </template>
    <template v-else>
      <EmptylodderBox></EmptylodderBox>
    </template>

    <template v-if="getTopCategories != ''">
      <TopCategorySlider
        :top_categories="getTopCategories"
        :translationloaded="translationloaded"
        :translate="translate"
      ></TopCategorySlider>
    </template>
    <template v-else>
      <EmptylodderBox></EmptylodderBox>
    </template>

    <div class="ps-deal-of-day " v-if="getDealoftheDayProducts != ''">
      <div class="container">
        <div class="ps-section__header">
          <div class="ps-block--countdown-deal">
            <div class="ps-block__left">
              <h3>{{ translate("hotDeals") }}</h3>
            </div>
          </div>
          <router-link :to="`/hot-deals`" class="">{{
            translate("viewAll")
          }}</router-link>
        </div>

        <template v-if="getDealoftheDayProducts != ''">
          <ProductSlider
            :products="getDealoftheDayProducts"
            @cartUpdated="cartUpdated($event)"
            :translationloaded="translationloaded"
            :translate="translate"
          ></ProductSlider>
        </template>
        <template v-else>
          <EmptylodderBox></EmptylodderBox>
        </template>
      </div>
    </div>
    <!-- home promo banner section start-->

    <template v-if="getPromoBanner2 != ''">
      <HomePromoBanner :banners="getPromoBanner2"></HomePromoBanner>
    </template>
    <template v-else>
      <EmptylodderBox></EmptylodderBox>
    </template>

    <!-- home promo banner section end-->
        
    <div class=" ps-deal-of-day best-seller " v-if="getPopularProducts != ''  && mobile == 0">
      <div class="ps-container ">
        <div class="ps-section__header">
          <div class="ps-block--countdown-deal">
            <div class="ps-block__left">
              <h3>{{ translate("bestSellers") }}</h3>
              <span class="subtxt">{{
                translate("soYouGetMeToKnowMeBetter")
              }}</span>
            </div>
          </div>
          <router-link :to="`/best-sellers`">{{
            translate("viewAll")
          }}</router-link>
        </div>

        <!-- best selleter slider start-->
        <template v-if="getPopularProducts != ''">
        
          <ProductSlider
            :products="getPopularProducts"
            @cartUpdated="cartUpdated($event)"
            :offerBadge="true"
            :newBadge="true"
            @scren_size_change="screnChanged"
            :translationloaded="translationloaded"
            :translate="translate"
          >
          </ProductSlider>
        </template>
        <template v-else>
          <EmptylodderBox></EmptylodderBox>
        </template>

        <!-- best selleter slider end-->
      </div>
    </div>
    <!-- removed class ps-deal-of-day -->
    <div class="best-seller onMobile " v-else-if="getPopularProducts != ''  && mobile ==1">
      <div class="ps-container ">
        <div class="ps-section__header">
          <div class="ps-block--countdown-deal">
            <div class="ps-block__left">
              <h3>{{ translate("bestSellers") }}</h3>
              <span class="subtxt">{{
                translate("soYouGetMeToKnowMeBetter")
              }}</span>
            </div>
          </div>
          <router-link class="view--all" :to="`/best-sellers`">{{
            translate("viewAll")
          }}</router-link>
        </div>

        <!-- best selleter slider start-->
        <template v-if="getPopularProducts != ''">
        
          <ProductSlider
            :products="getPopularProducts"
            @cartUpdated="cartUpdated($event)"
            :offerBadge="true"
            :newBadge="true"
            @scren_size_change="screnChanged"
            :translationloaded="translationloaded"
            :translate="translate"
          >
          </ProductSlider>
        </template>
        <template v-else>
          <EmptylodderBox></EmptylodderBox>
        </template>

        <!-- best selleter slider end-->
      </div>
    </div>


    <div class="ps-deal-of-day">
      <div class="ps-container">
        <div class="ps-section__header">
          <div class="ps-block--countdown-deal">
            <div class="ps-block__left">
              <h3>{{ translate("dailyShopping") }}</h3>
            </div>
          </div>
          <router-link :to="`products`">{{ translate("viewAll") }}</router-link>
        </div>

        <!--Daily shopping banner section start-->
        <DailyShopingBanner
          :banners="getDailyShoppingBaner"
        ></DailyShopingBanner>
        <!--Daily shopping banner section end-->
      </div>
    </div>
    <div class="ps-deal-of-day">
      <div class="ps-container">
        <div class="ps-section__header">
          <div class="ps-block__left">
            <h3>{{ translate("latestarrivals") }}</h3>
          </div>
           <!-- <router-link :to="`/best-sellers`">{{
            translate("viewAll")
          }}</router-link> -->
        </div>
            <!-- Latest arrival slider start-->

        <template v-if="getLatestArrival != ''">

          <ProductSlider
            :products="getLatestArrival"
            @cartUpdated="cartUpdated($event)"
            :offerBadge="true"
            :newBadge="true"
            @scren_size_change="screnChanged"
            :translationloaded="translationloaded"
            :translate="translate"
          >
          </ProductSlider>          
        </template>
        <template v-else>
          <EmptylodderBox></EmptylodderBox>
        </template>

        <!-- Latest arrival slider end-->  
      </div>
    </div>

    <div class="ps-deal-of-day kitchen-essentials-sec mb-3" v-if="false">
      <div class="ps-container">
        <div class="ps-section__header">
          <div class="ps-block--countdown-deal">
            <div class="ps-block__left">
              <h3>{{ translate("offers") }}</h3>
            </div>
          </div>
          <router-link :to="`products`">{{ translate("viewAll") }}</router-link>
        </div>

        <!--Price code category section start-->
        <PriceStoreBanner></PriceStoreBanner>
        <!--Price code category section end-->
      </div>
    </div>

    <!-- <div class="brand_slider">
             <div  class="ps-container">
                   <div class="ps-section__header">
                    <div class="ps-block--countdown-deal">
                        <div class="ps-block__left mb-3">
                            <h3>Trending Brands</h3>
                        </div>

                    </div>
                </div>
                 <BrandSlider
                 :brands="fetchTopBrandList"
                 >
                 </BrandSlider>
            </div>
       </div> -->

    <div id="homepage-ad">
      <div class="ps-home-banner">
        <template v-if="getPromoFullWidthBanner != ''">
          <BottomBanner :banners="getPromoFullWidthBanner"></BottomBanner>
        </template>
        <template v-else>
          <EmptylodderBox></EmptylodderBox>
        </template>
      </div>
    </div>

    <div
      class="ps-deal-of-day recent_view-sec"
      v-if="getRecentProducts.length > 4"
    >
      <div class="ps-container">
        <div class="ps-section__header">
          <div class="ps-block--countdown-deal">
            <div class="ps-block__left">
              <h3>{{ translate("recentlyViewedItems") }}</h3>
            </div>
          </div>
          <router-link :to="`/recently-viewed-items`">{{
            translate("viewAll")
          }}</router-link>
        </div>

        <!--Recent viewed product section start-->
        <template v-if="getRecentProducts != ''">
          <ProductSlider
            :products="getRecentProducts"
            @cartUpdated="cartUpdated($event)"
            :offerBadge="true"
            :newBadge="true"
            :translationloaded="translationloaded"
            :translate="translate"
          >
          </ProductSlider>
        </template>
        <template v-else>
          <EmptylodderBox></EmptylodderBox>
        </template>

        <!--Recent viewed product section end-->
      </div>
    </div>
   
   <div v-if="getOfferZone.length">
     <div class="ps-deal-of-day" v-for="item in getOfferZone " :key="item.catName">
      <div class="ps-container"  v-if="item.length" >         
       
        <div class="ps-section__header">                     
          <div class="ps-block__left" v-for="(val,index) in item" :key="index">                       
                 <h3 v-if="index < 1">{{ translate("topdealson") }} {{  val.catName}} </h3>

          </div>
           
        </div>        
         
        <template v-if="item">            
            <ProductSlider
            :products="item"
            @cartUpdated="cartUpdated($event)"
            :offerBadge="true"
            :newBadge="true"
            :translationloaded="translationloaded"
            :translate="translate"
          >
          </ProductSlider>
        </template>
        <template v-else>
          <EmptylodderBox></EmptylodderBox>
        </template>

       
      </div>
    </div>
   </div>
    
    

  </div>
  <span>
      <a href="https://api.whatsapp.com/send?phone=+971543888998" class="floatWhatsApp" target="_blank">
        <i class="fa fa-whatsapp my-float" id="loading"></i> </a>
  </span>
  
  <span class="scrollTop" @click="topToBottom">
    <i class="fa fa-angle-up" aria-hidden="true"></i>
  </span>
  <AdvertisementPopup
    :showModal="showModal"
    :templateData="popupTemplateData"
  ></AdvertisementPopup>
</template>

<script>
import HomeBanner from "@/components/HomeBanner";
// import BrandSlider from '@/components/BrandSlider';
import TopCategorySlider from "@/components/TopCategorySlider";
import ProductSlider from "@/components/ProductSlider";
import HomePromoBanner from "@/components/HomePromoBanner";
import BottomBanner from "@/components/BottomBanner";
import PriceStoreBanner from "@/components/PriceStoreBanner";
import DailyShopingBanner from "@/components/DailyShopingBanner";
import EmptylodderBox from "@/components/EmptylodderBox";
import AdvertisementPopup from "@/components/AdvertisementPopup";
import { mapGetters, mapActions } from "vuex";
// import $ from 'jquery';
import helper from "../helper/helper";

export default {
  name: "Home",

  components: {
    HomeBanner,
    TopCategorySlider,
    ProductSlider,
    HomePromoBanner,
    BottomBanner,
    PriceStoreBanner,
    DailyShopingBanner,
    EmptylodderBox,
    //    BrandSlider,
    AdvertisementPopup,
  },
  props: ["translationloaded", "translate"],
  watch: {
    fetchPopupbannerData(value) {
      let locationInfo = localStorage.getItem("location_info");
      if (value != "" && locationInfo) {
        let popupTemplate = value[0];
        if (popupTemplate.status == true) {
          this.openPopupbanner();
          this.popupTemplateData = popupTemplate;
        }
      }
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize);
        this.handleResize();

  },
   unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
  computed: {
    ...mapGetters([
      "getHomeBanner",
      "getTopCategories",
      "getDealoftheDayProducts",
      "getPromoBanner2",
      "getPopularProducts",
      "getLatestArrival",
      "getOfferZone",
      "getTopCategeories",
      "getRecentProducts",
      "getPromoFullWidthBanner",
      "getHomeMobBanners",
      "getDailyShoppingBaner",
      "fetchTopBrandList",
      "fetchPopupbannerData",
    ]),
  },
  data() {
    return {
      showModal: false,
      popupTemplateData: [],
       mobile: 0
    };
  },
  mounted() {
    this.storeDealoftheDayProducts();
    this.setPopularProduct();
    this.setLatestArrival(); 
    this.setofferzone();   
    // this.setTopCategeories();    
    this.storeRecentViewedProducts();

    this.isAuthUser();
    helper.backtoTop();
  },
  methods: {
    ...mapActions([
      "storeDealoftheDayProducts",
      "setPopularProduct",
      "setLatestArrival",
      "setofferzone",
      // "setTopCategeories",
      "storeRecentViewedProducts",
    ]),
    cartUpdated(customerCart) {
      this.$emit("cartUpdated", customerCart);
    },
    topToBottom() {
      helper.backtoTop();
    },
    openPopupbanner() {
      let popupCookie = this.$cookies.get("offerpop");
      if (popupCookie === null) {
        this.showModal = true;
        this.$cookies.set("offerpop", true, 60 * 60 * 24);
      }
    },
    isAuthUser() {
      let isLogedUserChk = "";
      if (this.$router.params != "") {
        isLogedUserChk = this.$route.query.login;
      }

      if (isLogedUserChk == "failed") {
        document.getElementById("login_popup_link").click();
      }
    },

    // handleScroll(){
    //     console.log(Math.round(window.pageYOffset));
    // }
    handleResize() {

         if(window.innerWidth < 601){

            this.mobile = 1;
         }else{
           this.mobile = 0;
         }
           
        }
  },
  // created() {
  //   // window.addEventListener('scroll', this.handleScroll);
  //   // this.handleScroll();
  // },
  // unmounted() {
  //   // window.removeEventListener('scroll', this.handleScroll);
  // },
};
</script>
<style scoped>
.ps-home-banner {
  padding-top: 20px;
}
#home_whatsapp_icon {
  position: fixed;
  top: 68%;
  width: 50px;
  right: 30px;
  cursor: pointer;
  z-index: 99999;
}
</style>
<style>
@media only screen and (max-width: 600px) {
  .modal-backdrop {
    z-index: 999999 !important;
  }
  .modal {
    z-index: 99999999 !important;
  }

  .modal-content {
    z-index: 9999999 !important;
  }
}
</style>
